.dispatch__tag {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #3D8F44;

    background: #F5F4F0;        
    border-radius: 3px;
    margin-left: 16px;
}

.search__filters .active {
    color: #3D8F44;
}

.none {
    display: none;
}

.dispatches__preview {
    background: #FDFBF7;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    height: fit-content;
}

.dispatches__preview a {
    text-decoration: none;
}

.dispatches__preview::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 4px;
    left: 4px;
    background: #FDFBF7;
    border: 1px solid #000000;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: -1;
}

.blog__preview__img img {
    width: 100%;
}

.dispatches__preview__content {
  padding-top: 32px;
  padding-left: 40px;
  padding-bottom: 28px;
  padding-right: 36px;
}

.dispatches__preview__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #000000;
    margin:0;
}

.info__container {
   display: flex;
   align-items: center;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dispatches__preview__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top:24px
}



.view-arrow {
    align-self: flex-end;

}

.dispatch__img {
margin-right: 12px;
}

.dispatch__author {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #666666;
}

.dispatches__container {
    height: fit-content;
     }

@media only screen and (max-width: 1050px) {
    
}

@media only screen and (max-width: 650px){

    .dispatch__tag {
        margin-left: 0;
        margin-bottom: 0;
    }
}
