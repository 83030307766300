
.join__content {
    display: grid;
    justify-content: center;
}

.join__wrapper {
    width: 1200px;
    margin: 0 auto;
  }
  
  .join__wrapper__arched {
    width: 1200px;
    margin: 0 auto;
   margin-top: -46vw;
    padding-top: 120px;
  }
  
  .join__div__arched {
    margin-top: calc( 46vw + 200px);
    background-color: #FDFBF7;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    z-index: -1;
    box-sizing: border-box;
    padding-bottom: 293px;
  }
  
  .join__div__arched::after {
    content: "";
    width: 92vw;
    height: 92vw;
    border-radius: 50%;
    background: #FDFBF7;
    border: 1px solid #E0E0E0;
    z-index: -2;
    position: absolute;
    top:  calc(-46vw + -0px);
    left: 50%;
    transform: translateX(-50%);
    clip-path: inset(0 0 50% 0);
  }


.join__content_phone {
    display: flex;
    justify-content: center;
}

.join__content_phone__holder {
    width: 345px;
    height: 700px;
    position: relative;
}

.join__ios__mockup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.discover_phone {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 310px;
}

.join__content_text {
    text-align: center;
    width: 761px;

}

.join__content_text h2 {
    font-family: Schnyder S;
    font-style: normal;
    font-weight:400;
    font-size: 100px;
    line-height: 113px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 60px;
    margin-bottom: 32px;
}

.join__content_text p {

    font-family: Avenir;
    font-style: normal;
    font-weight:normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #666666;
}

.join__content_text p:last-child {
    margin-bottom: 0;
}

.join__content_text p span {
    color: #3D8F44;
}

@media only screen and (min-width: 1550px){

    .join__content_text {
        width: 961px;
    }

    .join__div__arched {
        margin-top: calc( 46vw + 200px);
      }

    .join__wrapper {
        width: 1320px;
    } 
  
    .join__wrapper__arched {
      padding-top: 120px;
  }


    .join__content_phone__holder {
        width: 404px;
        height:819px;
    }

    .join__content_phone__holder .join__ios__mockup {
        width: 100%;
    }

    .discover_phone {
        left: 25px;
        width: 360px;
    }
   
    .join__content_text h2 {
        font-size: 117px;
        line-height: 132px;
        margin-top: 60px;
        margin-bottom: 32px;
    }

    .join__content_text p {
        font-size: 23px;
        line-height: 37px;
    }
      
    
}

@media only screen and (max-width: 1300px){
    .join__wrapper {
        width: 1000px;
    } 
  
    .join__wrapper__arched {
        width: 1000px;
    } 

}

@media only screen and (max-width: 1100px){

    .join__div__arched {
        margin-top: calc( 46vw + 160px);
        padding-bottom: 220px;
    }


    .join__content_text h2 {
        font-size: 90px;
        line-height: 100px;
    }
   
    .join__wrapper {
        width: 900px;
    } 
  
    .join__wrapper__arched {
        width: 900px;
    } 



    .join__content_phone__holder {
        height: 625px;
    }

    .join__content_phone__holder {
        width: 310px;
    }
    .join__content_phone .join__ios__mockup{
        width: 310px;
    }

    .join__content_phone .discover_phone{
        width: 274px;
    }

    .discover_phone {
        top: 15px;
        left: 18px;
    }
    
}

@media only screen and (max-width: 950px){
    .join__content_phone img{
        width: 290px;
    }

    .join__wrapper {
        width: 100%;
    } 
  
    .join__wrapper__arched {
        width: 100%;
    } 

}

@media only screen and (max-width: 800px){
    .join__content_text {
        width: 80%;
        margin: 0 auto;
    }

    .join__content_text h2{
        font-size: 80px;
        line-height: 103px;
    }

    
}

@media only screen and (max-width: 670px){
    .join__content_phone img{
        width: 250px;
    }

}

@media only screen and (max-width: 640px){
    .join__div__arched {
        padding-bottom: 100px;
        margin-top: calc( 46vw + 150px);

    }
    .join__wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

       
    } 
  
    .join__wrapper__arched {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        padding-top: 100px;
        padding-bottom: 100px;
    } 
}

@media only screen and (max-width: 590px){
    .join__content_text h2{
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 58px;
        line-height: 64px;
        text-align: center;
        letter-spacing: 0.02em;
    }

}

@media only screen and (max-width: 520px){
    .join__content_phone img, .join__content_phone__holder, .join__content_phone .join__ios__mockup{
        width: 210px;
        height: 423px;
    }

    .join__content_text {
        width: 100%;
    }
    .join__content_text h2{
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 54px;
        text-align: center;
        letter-spacing: 0.02em;
    }

    .join__content_text p {
        font-size: 17px;
        line-height: 29px;
    }

    .join__content_phone .discover_phone {
        width:188px;
        height: auto;
        top: 10px;
        left: 12px;
    }

    .join__div__arched {
        padding-bottom: 60px;
        margin-top: calc( 46vw + 80px);
    }

   
}

@media only screen and (max-width: 420px){
    .join .wrapper__arched {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-top: -46vw;

    }

    .join.div__arched {
        padding-bottom: 0;
        margin-top: calc( 46vw + 150px);
    }

    .join__content_text h2 {
        margin-top: 52px;
    }

}


