.navigation-container {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
  pointer-events: none;
 

}

.navigation.hide {
  transform: translateY(-100px);
}

.navigation-container .open {
  background: url('../images/noise.png'), #FDFBF7;
}

.navigation {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 0 32px;
    position: relative;
    z-index: 1;
    transition: 0.3s ease;
    pointer-events: none;
}

.navigation__menu {
  position: relative;
  pointer-events: all;
}

.navigation__menu, .navigation__menu__X, .navigation__menu__dots{
    width: 32px;
    height: 32px;
    position: relative;
}

.navigation__menu__X, .navigation__menu__dots {
  position: absolute;
  right: 0;
  top: 0;
}


.navigation__logo, .navigation__menu {
    cursor: pointer;
    pointer-events: all;
}

.more_dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.more_dot_1 {
  transform: translate(-50%, -15px);
  transition: all 0.3s ease;
}

.more_dot_3 {
  transform: translate(-50%, 8px);
  transition: all 0.3s ease;
}

.navigation__menu__dots.hide .more_dot_1 {
  transform: translate(-50%, -50%);
}

.navigation__menu__dots.hide .more_dot_3 {
  transform: translate(-50%, -50%);
}

.navigation__menu__dots.show, .navigation__menu__X.show {
    opacity: 1;
    pointer-events: all;
}

.navigation__menu__dots.hide, .navigation__menu__X.hide {
    opacity: 0;
    pointer-events: none;
}



.navigation__menu__X span {
    display: block;
    width: 30px;
    height: 1.3px;
    background-color: #333333;
    position: absolute;
    top: 50%;
    left: 1px;
}



 .up.show {
  animation: rotateUpShow 0.5s ease forwards;
}

.up.hide {
  animation: rotateUphide 0.9s ease forwards;
}

@keyframes rotateUpShow {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(45deg);
  }
}

@keyframes rotateUphide {
  0% {
    transform: rotate(45deg);
    clip-path: inset( 0 0 0 0);
  }

  80% {
    transform: rotate(90deg);
    clip-path: inset( 0 0 0 0);
  }

  100% {
    transform: rotate(90deg);
    clip-path: inset(0 15px 0 15px);
  }
}
.down.show {
  animation: rotateDownShow 0.5s ease forwards;
}

.down.hide {
  animation: rotateDownhide 0.9s ease forwards;
}

@keyframes rotateDownShow {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(135deg);
  }
}

@keyframes rotateDownhide {
  0% {
    transform: rotate(135deg);
    clip-path: inset( 0 0 0 0);
  }

  80% {
    transform: rotate(90deg);
    clip-path: inset( 0 0 0 0);
  }

  100% {
    transform: rotate(90deg);
    clip-path: inset(0 15px 0 15px);
  }
}

@media only screen and (min-width: 1550px){
  .navigation {
    padding: 32px 60px 0 60px;
  }
}


@media only screen and (max-width: 1200px){
  .navigation {
    padding-bottom: 40px;
    background: linear-gradient(180deg,rgba(245,244,240,1) 0%, rgba(245,244,240,0.7) 60%, rgba(245,244,240,0) 100%);
  }
}

@media only screen and (max-width: 770px){
  .navigation {
    padding-bottom: 40px;
    background: linear-gradient(180deg,rgba(245,244,240,1) 0%, rgba(245,244,240,0.7) 60%, rgba(245,244,240,0) 100%);
  }
}



@media only screen and (max-width: 520px){
  .navigation {
    padding: 32px 20px 0 20px;
    pointer-events: none;
  }

  .navigation {
    padding-bottom: 20px;
    background: linear-gradient(180deg,rgba(245,244,240,1) 0%, rgba(245,244,240,0.7) 60%, rgba(245,244,240,0) 100%);
  }

  .navigation__menu {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

}






