.footerLegal-container {
    padding-bottom: 80px;
}

.wrapper__privacy {
    width: 1132px;
    margin: 0 auto;
}
.footerLegal__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 113px;
    letter-spacing: 0.5px;
    color: #000000; 
    margin-top: 134px;   
    margin-bottom: 40px;
}

.footerLegal__effective-date {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: 0.3px;
    color: #999999;
    margin-bottom: 44px;
}

.footerLegal__effective-date span {
    color: #3D8F44;
}

.footerLegal__content {
   border-top: 1px solid #e0e0e0;
  /* width: 884px;*/
  width: 920px;
}

.footerLegal__paragraph {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #666666; 
    margin-top: 36px;
}

.footerLegal__paragraph a{
   color: #3D8F44;
}

.privacyNotice__navigation {
    margin-top: 44px;
    width: 60%;
}

.privacyNotice__navigation__link {
    font-family: Schnyder S;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 32px;
    transition: 0.3s ease;
    cursor: pointer;
}

.privacyNotice__navigation__link:hover {
    color: #3D8F44;
}

.sections__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 59px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 100px;
}

.information__subtitle, .footerLegal__subtitle {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 41px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 100px;
}

.footerLegal__list_nonstyled {
  list-style: none;
  padding-left: 0;
}

.footerLegal__list_nonstyled p  {
   margin-top: 0;
   margin-bottom: 0;
}

.footerLegal__orderedList {
    list-style-type: lower-alpha;
    color: #999999;
}

.footerLegal__orderedList__numbers {
    color: #999999;
}

.footerLegal__list__bold {
   color: black;
}



@media only screen and (max-width: 1500px){
    .wrapper__privacy {
        width: 970px;
    }
}

@media only screen and (max-width: 1300px){
    .wrapper__privacy {
        width: 100%;
        padding-left: 150px;
        box-sizing: border-box;
    }

}

@media only screen and (max-width: 1200px){
    .wrapper__privacy {
        padding-left: 50px;
    }

    .go-back {
        left: 50px;
        top: 32px;
    }
}

@media only screen and (max-width: 1100px){
    .footerLegal__content {
        width: calc(100% - 50px);
    }

    .footerLegal__title {
        font-size: 80px;
        line-height: 93px;
    }
}

@media only screen and (max-width: 900px){
    .go-back {
        left: 50px;
    }

    .footerLegal-container .wrapper {
        padding-left: 50px;
    }

}


@media only screen and (max-width: 650px){
    .footerLegal__content{
        width: 100%;
    }
}

@media only screen and (max-width: 650px){
   .wrapper__privacy {
        padding-left: 20px;
    }

    .go-back {
        left: 20px;
        top: 32px;
    }
}



@media only screen and (max-width: 520px){
    .wrapper__privacy {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .footerLegal__paragraph {
        font-size: 17px;
        line-height: 29px;
        margin-top: 24px;
    }

    .footerLegal__title{
        font-family: Schnyder L;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 54px;
        letter-spacing: 0.5px;
        color: #000000;
        margin-top: 100px;
        margin-bottom: 20px;
        width: 80%;
    }

    .footerLegal__effective-date {
        margin-bottom: 20px;
    }

    .privacyNotice__navigation__link {
        font-size: 24px;
        line-height: 28px;
        margin-top: 28px;
        margin-bottom: 28px;
    }

    .sections__title {
        font-size: 36px;
        line-height: 42px;
        margin-top: 60px;
        margin-bottom: 24px;
    }

    .information__subtitle, .footerLegal__subtitle {
        margin-top: 60px;
        margin-bottom: 24px;
    }
}


@media only screen and (max-width: 420px){

    

    .footerLegal-container a {
        word-wrap: break-word;  
    }

    .footerLegal__list {
        padding-left: 20px;
    }

    .footerLegal__list li::after {
        left: -20px;
    }

    .footerLegal-container .go-back{
        display: none;
    }    

}