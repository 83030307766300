.news {
    padding-top: 124px;
    padding-bottom: 124px;
}

.news__headline {
    display: grid;
    justify-content: center;
}

.wrapper__news {
    width: 1200px;
    margin: 0 auto;
}


.news__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 160px;
    line-height: 181px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
     color: #000000;
     margin: 0;
}

.news__title  span {
    color: #3D8F44;
}
.news__subtitle {
    margin: 0 auto;
    width: 654px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #666666;
}

.news__info {
    margin-top: 40px;
    border-top: 1px solid #E0E0E0;
    padding-top: 22px;
    padding-bottom: 22px;
}

.news__info p  {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;  
    color: #999999;
    margin: 0;
}
.news__info p span {
    font-family: Schnyder S;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
}

.news__info .wrapper__news {
    width: 1000px;
    display: flex;
    justify-content: space-between;
}

.news__content__container {
    display: flex;
    flex-direction: column;
}

.news__content {
    padding-top: 80px;
    min-height: 600px;
    background-image: url("../images/news__bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    display: flex;
    justify-content: center;
   
}

.news__content__posts {
    width: 600px;
    background: url('../images/noise.png'), #F5F4F0;
    min-height: 400px;
    align-self: center;
}

.news__content__fedback {
    width: 600px;
    height: 400px;
    background: #FDFBF7;
    border: 1.5px solid #3D8F44;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 0 80px 60px 80px;
    display: flex;
    align-items: flex-end;
    position: relative;
    align-self: center;
    margin-top: 80px;
}


.feedback__statue {
    position: absolute;
    top: -52px;
    left: 50%;
    transform: translateX(-50%);
}

.news__content__fedback__titile {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 20px;
}

.news__content__fedback__subtitile {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #666666;
    margin-bottom: 0;
}

.feedback__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.feedback__btn__yes {
    width: 207px;
    height: 48px;
    background: #3D8F44;
    border-radius: 2px;
    border: none;
}

.feedback__btn__yes span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.feedback__btn__no {
    width: 207px;
    height: 48px;
    background: #FDFBF7;
    border: 1px solid #3D8F44;
    border-radius: 2px;
    box-sizing: border-box;
}

.feedback__btn__no span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #3D8F44;
}

.news__hear__from__columnist__container {
    display: flex;
    flex-direction: column;
}

.news__hear__from__columnist {
  display: grid;
  grid: auto / repeat(6, 1fr);
  gap: 16px;
}
.columnist__holder {
  grid-column: span 2;
}

.news__hear__from__columnist .columnist__holder:nth-child(5n), 
.news__hear__from__columnist .columnist__holder:nth-child(5n-1) {
  grid-column: span 3;
}

.news__hear__from__columnist .columnist__holder:nth-child(5n-1) {
    justify-self: end
  } 

.news__hear__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: 80px;
    margin-bottom: 72px;
}


.columnist {
    width: 250px;
    height: 60px;
    background: #FDFBF7;
    border-radius: 22px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
}

.columnist__name {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin:0;
}

.columnist__position {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    margin:0;
}

.columnist__info {
    margin-left: 12px;
}

.news__hear__btn {
    width: 310px;
    height: 52px;
    background: #3D8F44;
    border-radius: 2px;
    border: none;
    align-self: center;
    margin-top: 60px;
    position: relative;
}

.news__hear__btn::after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid #3D8F44;
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 4px;
    box-sizing: border-box;
}

.news__hear__btn span{
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin:0;
}

@media only screen and (max-width: 1300px){
    .news__title {
        display: block;
    }

    .wrapper__news {
        width: 1100px;
    }
}

@media only screen and (max-width: 1100px){
    .news__info .wrapper__news {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    }

    .wrapper__news {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    } 
}



@media only screen and (max-width: 1000px){
    .news__info .wrapper__news {
        flex-direction: column;
        width: 600px;
        padding: 0;
    }

    .news__info p {
        justify-content: center;
    }

    .news__info__date, .news__info__update {
        margin-bottom: 24px;
    }

   
}

@media only screen and (max-width: 850px){
    .news__title {
        font-size: 120px;
        line-height: 141px;
    }

    .news__subtitle {
        width: 100%;
    }

    .news__scroll {
        width: 100%;
        overflow-x: scroll;
    }

    .news__scroll::-webkit-scrollbar {
        display: none;
    }
   
   
    .news__hear__from__columnist {
        width: 782px;
    }
}

@media only screen and (max-width: 650px){
    .news__title {
        font-size: 100px;
        line-height: 121px;
    }
    .news__content__container, .news__content__posts {
        width: 100%;
        box-sizing: border-box;
    }

    .news__content__fedback {
        width: 90%;
        padding: 0 40px 20px 40px; ;
        margin: 0 auto;
      
    }

    .news__info .wrapper__news {
        flex-direction: column;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .news__info__date, .news__info__update {
        margin-bottom: 12px;
    }

    .news {
        padding-top: 80px;
    }

    .news__info {
        margin-top: 24px;
    }

}

@media only screen and (max-width: 575px){
    .news__title {
        font-size: 80px;
        line-height: 101px;
    }

    .feedback__btn__yes, .feedback__btn__no {
        width: 45%;
    }
}

@media only screen and (max-width: 500px){ 
.news__content__fedback__titile {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    /* or 112% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #000000;
}

.news__content__fedback {
    height: 350px;
}

 }

@media only screen and (max-width: 450px){
    .news__title {
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 76px;
        line-height: 80px;
        /* or 105% */

        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .news__content__fedback {
        padding: 0 0 40px 0;
    }

    .feedback__btns {
        padding-left: 20px;
        padding-right: 20px;
    }

    .news__content__fedback__titile {
        padding-left: 10px;
        padding-right: 10px;
    }

    .news__content {
        padding-top: 150px;
    }

   
}



