.record__wrapper {
    width: 1200px;
    margin: 0 auto;
  }
  
  .record__wrapper__arched {
    width: 1200px;
    margin: 0 auto;
   margin-top: -46vw;
    padding-top: 156px;

    display: grid;
    justify-content: center;
    
  }
  
  .record__div__arched {
    margin-top: calc( 46vw + 300px);
    background-color: #FDFBF7;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    z-index: -1;
    box-sizing: border-box;
    padding-bottom: 270px;

    margin-top: 0;
    z-index: 1;
    background: #F5F4F0;
    padding-bottom: 100px;


    
  }
  
  .record__div__arched::after {
    content: "";
    width: 92vw;
    height: 92vw;
    border-radius: 50%;
    background: #F5F4F0;
    border: 1px solid #E0E0E0;
    z-index: -2;
    position: absolute;
    top:  calc(-46vw + -0px);
    left: 50%;
    transform: translateX(-50%);
    clip-path: inset(0 0 50% 0);
  }
  

.record__background {
    height: calc(46vw + 144px);
    background: url("../images/c_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.record__headline {
   display: flex;
   flex-direction: column;
}

.record__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 113px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 40px;
}

.rt1 {
    opacity: 0;
    animation: 0.7s rollout forwards 1s;
}

.rt2 {
    opacity: 0;
    animation: 0.7s rollout forwards 1.2s;
}

.rt3 {
    opacity: 0;
    animation: 0.7s rollout forwards 1.4s;
}

.rt4 {
    opacity: 0;
    animation: 0.7s rollout forwards 1.6s;
}

.rt5 {
    opacity: 0;
    animation: 0.7s rollout forwards 1.8s;
}

.rt6 {
    opacity: 0;
    animation: 0.7s rollout forwards 2s;
}

@keyframes rollout {
    0% {
      transform: translateY(100px) rotate(2deg);
      opacity: 0;
    }
    100% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
  }
  

.record__subtitle{
    width: 500px;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #666666;
    align-self: center;
    margin-top: 0;
    margin-bottom: 0;
}

.record__form {
    width: 646px;
}


.record__form__subtitle {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #000000;
    position: relative;
    margin-top: 60px;
    margin-bottom: 0;
}

.record__form__subtitle::after {
    content: "01";
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 136px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #3D8F44;
    opacity: 0.05;
    position: absolute;
    top: -60px;
    left: -152px;
}

.subtitle__confidentiality::after {
    content: "02";
    top: -60px;
    left: -172px;
}






/* test input */

.record__form__uper {
    display: flex;
    justify-content: space-between;
}



.record__form__uper .request__input, 
.record__form__uper .request__input .fieldset, 
.record__form__uper .request__input input {
    width: 311px;
    box-sizing: border-box;
}



.test_input {
    width: 312px;
    height: 52px;   
    position: relative;
}

.test_input input:focus-visible, .record__form__textarea {
    outline: none;
}

.test_input input {
    width: 100%;
    height: 100%;
    background: #FDFBF7;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 2px;
    border-bottom-color: #000000;
    padding-left: 16px;
}

.test_input span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #999999;
    position: absolute;
    top: 15px;
    left: 16px;
    pointer-events: none;
    transition: 0.3s ease;
    padding-left: 5px;
    padding-right: 5px;
}

/*
.record__form input::placeholder {
    opacity: 0;
  }

.IN:focus-visible + .SP  {
    transform: translate(-10px, -25px) scale(0.75);
    background:  linear-gradient( to top, #FDFBF7 60%, rgba(0,0,0,0) 40%)
}

 .test_input:focus-within + .SP{
    width: 64px;
}
  
  .IN:not(:placeholder-shown) ~ .SP {
    transform: translate(-10px, -25px) scale(0.75);
    background:  linear-gradient( to top, #FDFBF7 60%, rgba(0,0,0,0) 40%)
  }


  */
/* - - - - - */

.record__form__lower {
    width: 100%;
    height: 212px;
    background: #FDFBF7;
    margin-top: 24px;
    position: relative;
}

.record__form__textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box ;
    background: none;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    resize: none;
    padding-left: 20px;
    padding-top: 20px;

    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18.6667px;
    line-height: 25px;
    color: #666666;
}

.record__form__lower label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #666666;
    pointer-events: none;
    transform: translate(16px, 16px);
    transition: 0.3s ease;
    transform-origin: left;
}

.record__form__textarea:focus-visible + .record__form__span {
    transform: translate(-10px, -25px) scale(0.75);
    background:  linear-gradient( to top, #FDFBF7 60%, rgba(0,0,0,0) 40%)
}

.record__form__span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #999999;
    position: absolute;
    top: 15px;
    left: 16px;
    padding-left: 5px;
    padding-right: 5px;
    transition: 0.3s ease;
    pointer-events: none;
}

.record__form__radial__PN,
 .record__form__radial__PN label,
 .confidentiality__internal label,
 .confidentiality__other label {
    display: flex;
    align-items: center;
}

.record__form__radial {
    margin-top: 28px;
  }

  .request__input {
    width: 283px;
    height: 52px;
    position: relative;
    background: #FDFBF7;
    margin-top: 34px;
  }
  
  .request__input input{
    width: 283px;
    height: 52px;
    background: none;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    padding-left: 17px;
  
  
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18.6667px;
    line-height: 25px;
  
  
    color: #666666;
  }
  
  .request__input input:focus-visible  {  
    outline: none;
  }
  
  .request__input label{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #666666;
    pointer-events: none;
    transform: translate(16px, 16px);
    transform-origin: left;
    transition: 0.3s ease;
    
  }

  .label.Active  {
    transform: translate(20px, -10px) scale(0.75);
  }

  .fieldset {
    position: absolute;
    top: 0px;
    left: -2px;
    width: 283px;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #999999;
    border-bottom-color: #000000;
    border-radius: 2px;
    pointer-events: none;
    padding: 0 0 0 15px;
  }
  
  .legend {
    width: 0;
    padding: 0;
    transition: 0.3s ease;
  }
  
  .legend.Active {
    width: 66px;
    padding: 0;
  }
  
  .legend.email.Active {
    width: 64px;
    padding: 0;
  }
  
  .legend.expertise.Active {
    width: 88px;
    padding: 0;
  }

  .fieldset.Active {
      border-bottom-color: #3d8f44;
  }

  .record__form__lower .fieldset.Active {
    border-bottom-color: #3d8f44;
}




/*




.record__form__radial span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-left: 14px;     
}

.record__form__radial label {
    margin-right: 60px;
}

.record__form__radial [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .record__form__radial [type=radio] + img {
      cursor: pointer;
  }

*/

.confidentiality__holder {
    display: flex;
    margin-top: 32px;
}

.confidentiality__other {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap:10px;
}

.record__submit {
    width: 300px;
    height: 60px;
    margin-top: 62px;
    position: relative;
}

.record__form__submit {
    width: 300px;
    height: 60px;
    background: #AFB6B0;
    border-radius: 2px;
    border: none;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    transition: 0.3s ease;
}

.record__form__submit:hover {
    transform: translate(4px, 4px);
}

.record__form__submit.non__clickable {
    pointer-events: none;
}

.record__form__submit.clickable {
    pointer-events: all;
    background: #3D8F44;
}

.record__submit::after {
    content: "";
    width: 300px;
    height: 60px;
    border: 1px solid #AFB6B0;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;
}

.record__submit.clickable::after {
    border-color: #3D8F44;
}

.record__form__lower .fieldset {
    position: absolute;
    top: 0px;
    left: -2px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #999999;
    border-bottom-color: #000000;
    border-radius: 2px;
    pointer-events: none;
    padding: 0 0 0 15px;
}

.record__form__lower .description.Active {
    width:102px;
}

@media only screen and (max-width: 1300px){
    .record__wrapper__arched{
        width: 1100px;
    }
}





@media only screen and (max-width: 1150px){
    .record__form__subtitle::after {
        right: 0;
        left: auto;
    }
}

@media only screen and (max-width: 1100px){
    .record__wrapper__arched{
        width: 900px;
    }
}
@media only screen and (max-width:990px){
    .record__title {
        font-size: 80px;
    }
}

@media only screen and (max-width: 950px){
    .record__wrapper__arched{
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width:790px){
    .record__title {
        font-size: 70px;
    }
}

@media only screen and (max-width:700px){
    .record__title {
        font-size: 60px;
    }

    .record__form__uper{
        flex-direction: column;
    }

    .record__form__uper .request__input, 
    .record__form__uper .request__input .fieldset, 
    .record__form__uper .request__input input,
     .record__form__lower, 
     .record__form__submit,
     .record__form__submit::after {
        width:100%;
        box-sizing: border-box;
    }

    .record__form {
        width: 90vw;
    }

    .confidentiality__internal {
        padding-bottom: 20px;
    }

    .confidentiality__holder{
        flex-direction: column;
    }

    .confidentiality__other {
        padding-top: 20px;
        border-top: 1px solid #E0E0E0;
    }

    .record__subtitle {
        width: 80%;
    }
}

@media only screen and (max-width: 640px){
    .record__wrapper__arched{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width:550px){
    .record__title {
        font-size: 40px;
        line-height: 50px;
    }

}

@media only screen and (max-width:550px){
    .record__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    color: #000000; 
 }

 .record__wrapper__arched {
     padding-top: 80px;
 }

 .record__submit,  .record__submit:after {
     width: 100%;
 }

}

@media only screen and (max-width:460px) {

  .record__form__radial__PN, .record__form__radial__PN label, .confidentiality__internal label, .confidentiality__other label {
    line-height: 30px;
    height: 30px;
    align-items: center;
   
  }

  .custom__line__height {
    line-height: 18px!important;
  }

  .confidentiality__other {
    grid-row-gap:20px;
    padding-top: 28px;
  }

  .record__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

}







@media only screen and (min-width: 1550px){
    .record__title {
        font-size: 117px;
        line-height: 132px;
    }

    .record__subtitle {
        font-size: 23px;
        line-height: 37px;
        width: 700px;
    }

    .record__form__subtitle {
        font-size: 37px;
        line-height: 42px;
    }

    .record__form {
        width: 753px;
    }

    .record__form__uper .request__input, .record__form__uper .request__input .fieldset, .record__form__uper .request__input input {
        width: 362px;
        box-sizing: border-box;
    }

    .record__form__lower {
        height: 260px;
    }

}


/* The container 
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 64px;
  }

  .container span {
    font-size: 22px;
    line-height: 26px;
  }

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 24px;
    border: 1px solid #3D8F44;
    box-sizing: border-box;
    border-radius: 14px;
  }

  .checkmark__inside {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 14px;
    background: #3D8F44;
    z-index: -1;
    transform: scale(0.0);
    transition: 0.15s ease;
  }
  
 
 

  .container input:checked + .checkmark__inside {
   transform: scale(1.0);
  }
  

  .checkmark:after {
    content: url('../images/Check_radio.svg');;
    position: absolute;
    display: none;
  }
  
 
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  

  .container .checkmark:after {
    top: -3px;
    left: 6px;

  }

  .createC__plan {
    display: flex;
    align-items: center;
  }

  .plan__radio {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    
 
    
    color: #333333;

  }

  
  */


  /* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 64px;
  }

  .container span {
    font-size: 22px;
    line-height: 26px;
  }

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 24px;
    border: 1px solid #3D8F44;
    box-sizing: border-box;
    border-radius: 14px;
  }

  .checkmark__inside {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 14px;
    background: #3D8F44;
    z-index: -1;
    transform: scale(0.0);
    transition: 0.15s ease;
  }
  
  /* On mouse-over, add a grey background color */
 
  
  /* When the radio button is checked, add a blue background */
  .container input:checked + .checkmark__inside {
   transform: scale(1.0);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: url('../images/Check_radio.svg');;
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: -2px;
    left: 6px;

  }

  .createC__plan {
    display: flex;
    align-items: center;
  }

  .plan__radio {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    
    /* Almost Black */
    
    color: #333333;

  }