#canvas {
  position: absolute;
  height: 100vh !important;
  overflow: hidden;
}

.grid {
  height: 150vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  animation: gridShow 0.5s ease forwards 1s;
}
@keyframes gridShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.grid::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("../../images/noise.png"),
    linear-gradient(
      0deg,
      rgba(245, 244, 240, 1) 0%,
      rgba(245, 244, 240, 1) 30%,
      rgba(245, 244, 240, 0) 41%,
      rgba(245, 244, 240, 0) 100%
    );
  background-repeat: repeat;
}
