.dispatch__wrapper {
    width: 1132px;
    margin: 0 auto;
}
.dispatch-holder {
    padding-bottom: 120px;
}

.dispatch-holder .dispatch {
    width: 900px;

}

.dispatch__headline  {
    padding-bottom: 40px;
    padding-top: 134px;
}

.dispatch__headline a {
    text-decoration: none;
}

.dispatch__headline  h2 {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 113px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 20px;
}

.dispatch__content{
    border-top: 1px solid #E0E0E0;
}

.dispatch__content p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 0.3px;
    color: #333333;
    margin-bottom: 40px;
    margin-top: 40px;
}

.dispatch__content img {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    margin-top: 60px;
}

.dispatch__footer {
    border-top: 1px solid #E0E0E0;
    display: flex;
}

.dispatch__footer p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.3px;
    color: #000000;
    margin-right: 24px;
    margin-top: 32px;
}

.dispatch__footer img {
    margin-right: 40px;
}

@media only screen and (max-width: 1500px){
   
    .dispatch__wrapper  {
        width: 1000px;
    }



}

@media only screen and (max-width: 1200px){
    .dispatch-holder .go-back {
        display: none;
    }
    .dispatch__wrapper  {
        width: 100%;
        padding-left: 50px; 
        box-sizing: border-box;
    }   

}

@media only screen and (max-width: 1050px){
    .dispatch-holder .dispatch {
        width: 100%;
    }

    .dispatch__wrapper  {

        padding-right: 50px; 
    }  
}

@media only screen and (max-width: 650px){
    .dispatch__wrapper  { 
        padding-left: 20px;
        padding-right: 20px;
    }  
}

@media only screen and (max-width: 420px){
    .dispatch__headline h2{
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 54px;
        letter-spacing: 0.02em;

        /* Black */

        color: #000000;
    }

    .dispatch__content p {
        font-size: 22px;
    }
}
