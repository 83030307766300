.profile {
    padding-bottom: 100px;
}


.profile  .disptches__search{
   padding-top: 11px;
}

.profile .dispatches {
    margin-top: 24px;
}

.profile__wrapper {
    width: 1300px;
    margin: 0 auto;
}

.profile__headline {
    margin-top: 154px;
    display: grid;
    grid-template-columns: 350px 600px 350px; 
    padding-bottom: 24px;
    border-bottom: 1px solid #E0E0E0;
}

.profile__headline__avatar {
    display: grid;
    justify-content: end;
    align-content: end;
}

.profile__headline__avatar img {
    margin-right: 20px;
}

.profile__headline__title h2, .profile__content__bio__title{
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #000000;
    display: flex;
    margin: 0;
}

.profile__content__bio {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
}

.profile__content {
    padding-top: 21px;
    display: grid;
    grid-template-columns: 300px 600px 300px; 
    grid-column-gap: 50px;
}

.profile__content__bio__socials img {
    margin-right: 36px;

}

.load__more__profile {
    cursor: pointer;
    margin-top: 46px;
    width: 100%;
    height: 48px;
    background: #FDFBF7;
    border: 1px solid #999999;
    border-radius: 2px;
    transition: 0.3s;
}

.load__more__profile p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 48px;
    margin: 0;

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    transition: 0.3s;
}

.load__more__profile:hover {
    border: 1px solid #3D8F44;
}

.load__more__profile:hover p  {
    color: #3D8F44;
}

@media only screen and (max-width: 1500px){
    .profile__wrapper {
        width: 1200px;
    }
}

@media only screen and (max-width: 1300px){
    .profile__wrapper {
        width: 100%;
        padding-left:60px;
        padding-right:60px;
        box-sizing: border-box;

    }
}

@media only screen and (max-width: 1300px){
    .profile__content {
        grid-template-columns: 600px;
        justify-content: center;
    }

    .profile__headline {
        grid-template-columns: 50px 550px;
        justify-content: center;
    }

    .profile__headline__avatar {
        justify-content: start;
    }

    .profile__content__dispatches {
        margin-top: 50px;
        border-top: 1px solid #E0E0E0;
    }

    .profile__headline {
        border: none;
    }

    .profile__content__bio__wrapper {
        border-top: 1px solid #E0E0E0;
        padding-top: 22px;
    }
}


@media only screen and (max-width: 700px){
    .profile__content{
        grid-template-columns: 100%;
    }

    .profile__headline {
        grid-template-columns: 20% 80%;
    }
}

@media only screen and (max-width: 650px){
    .profile__wrapper {  
        padding-left:20px;
        padding-right:20px;
    }
}



