.calculator {
    padding-bottom: 132px;
    padding-top: 120px;
}

.calculator__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 113px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 54px;
}

.calculator__container {
    width: 1132px;
    height: 616px;
    background: #FDFBF7;
    border-radius: 22px;
    margin-bottom: 80px;
    box-sizing: border-box;
    padding-top: 66px;
    padding-left: 80px;
    display: grid;
    grid-template-columns: 440px 440px;
    grid-column-GAP: 36px;
}


.calculator__disclamer__container {
    width: 1100px;
}

.disclamer__title {
    font-family: Schnyder S;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 28px;
   
}

.disclamer__text {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    color: #666666;
    margin-bottom: 0;
}

.calculator__input, .calculator__input input, .calculator__input .fieldset{
    width: 440px;
}

.ColumnName legend.Active {
    width: 86px;
}

.InitialInvite legend.Active {
    width: 116px;
}

.SubscriptionsPercent legend.Active {
    width: 188px;
}

.CostPerMonth legend.Active {
    width: 92px;
}

.Annual legend.Active {
    width: 190px;
}

.Annual a {
    color: #3D8F44;
    opacity: 0;
    transition: 0.3s ease;
}

.Annual .label.Active .annual__link {
    opacity: 1;
    pointer-events: all;
}

.calculator__inpputs__NumberOfInvites__holder {
    display: flex;
    justify-content: space-between;
}

.calculator__inpputs__NumberOfInvites__holder .calculator__input,
 .calculator__inpputs__NumberOfInvites__holder .fieldset,
 .calculator__inpputs__NumberOfInvites__holder .calculator__input input{
    width: 210px;
}

.NumberOfInviteCycles legend.Active {
    width: 138px;
}

.NumberOfInvitesLow legend.Active {
    width: 130px;
}

.NumberOfInvitesHigh legend.Active {
    width: 134px;
}

.LaunchPartnership legend.Active {
    width: 110px;
}

.compute {
    width: 200px;
    height: 60px;
    background: #3D8F44;
    border-radius: 2px;
    border: none;
    margin-top: 48px;
    position: relative;
    z-index: 1;
}

.compute::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 2px;
    box-sizing: border-box;
    top: 4px;
    left: 4px;
    border: 1px solid #3D8F44;
    z-index: -1;
}

.compute span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 60px;
    margin: 0;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;

}

@media only screen and (max-width: 1300px){
    .calculator .wrapper {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 1250px){
    .calculator__container {
        width: 1032px;
        padding-left: 40px;
    }

    .calculator__title {
        font-size: 90px;
        line-height: 103px;
    }
    .calculator__disclamer__container {
        width: 100%;
    }
}

@media only screen and (max-width: 1150px){
    .calculator__container {
        grid-template-columns: 1fr;
    }
    .calculator__container {
        width: 540px;
        height: auto;
        padding-bottom: 66px;
    }
    .calculator__inpputs__NumberOfInvites__holder {
        width: 440px;
    }

   
}


@media only screen and (max-width: 700px){
    .calculator__title {
        font-size: 70px;
        line-height: 79px;
    }
}

@media only screen and (max-width: 650px){
    .calculator__disclamer__container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }  

    .calculator__title {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        font-size:50px;
        line-height: 58px;
    }
    .calculator .wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .calculator__inpputs__NumberOfInvites__holder {
        flex-direction: column;
    }

    .calculator__input {
        width: 90%;
    }

    .calculator__inpputs__NumberOfInvites__holder .calculator__input, 
    .calculator__inpputs__NumberOfInvites__holder .fieldset,
     .calculator__inpputs__NumberOfInvites__holder .calculator__input input,
     .compute {
         width: 90%;
     }

     .calculator__input input, .calculator__input .fieldset, .calculator__inpputs__NumberOfInvites__holder {
        width: 100%;
        box-sizing: border-box;
     }

    .calculator__container {
        width: 100%;
    }
}

@media only screen and (max-width: 580px){
  

   
}



