.generate__wrapper {
  width: 1200px;
  margin: 0 auto;
}

.generate__wrapper__arched {
  width: 1200px;
  margin: 0 auto;
  margin-top: -46vw;
  padding-top: 120px;
}

.generate__div__arched {
  background-color: #fdfbf7;
  border-top: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
  margin-top: 0;
  z-index: 0;
  padding-bottom: 160px;
  border-bottom: none;
}

.generate__div__arched::after {
  content: "";
  width: 92vw;
  height: 92vw;
  border-radius: 50%;
  background: #fdfbf7;
  border: 1px solid #e0e0e0;
  z-index: -2;
  position: absolute;
  top: calc(-46vw + -0px);
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 0 50% 0);
}

.generateRevenue.div__arched {
  margin-top: 0;
  z-index: 0;
  padding-bottom: 230px;
  border-bottom: none;
}

.generateRevenue__content {
  display: grid;
  justify-content: center;
}

.generateRevenue__content__phone {
  margin: 0 auto;
  height: 500px;
  width: 348px;
}

.generateRevenue__content__phone__holder {
  position: relative;
}

.generateRevenue__ios__mockup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.joined_phone {
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 1;
  width: 310px;
}

.algo_control {
  top: 16px;
  left: 20px;
  z-index: 1;
  width: 310px;
}

.generateRevenue__content__text {
  text-align: center;
  width: 761px;
}

.generateRevenue__title {
  font-family: Schnyder L;
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 113px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 120px;
  margin-bottom: 40px;
  margin-top: 60px;
}

.generateRevenue__subtitle {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #666666;
  margin-bottom: 40px;
}

.generateRevenue__subtitle span {
  color: #3d8f44;
}

.generateRevenue__content__phone {
  width: 350px;
}

@media only screen and (min-width: 1550px) {
  .generate__div__arched {
    padding-bottom: 200px;
  }

  .generate__wrapper {
    width: 1320px;
  }

  .generate__wrapper__arched {
    padding-top: 120px;
  }
  .generateRevenue__content__text {
    width: 860px;
  }

  .generateRevenue__content__phone {
    width: 404px;
    height: 500px;
  }
  .generateRevenue__ios__mockup {
    width: 404px;
    height: 500px;
  }

  .joined_phone {
    width: 360px;
  }

  .generateRevenue__title {
    font-size: 116px;
    line-height: 132px;
    margin-top: 60px;
    margin-bottom: 32px;
  }

  .generateRevenue__subtitle {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 1300px) {
  .generate__wrapper {
    width: 1000px;
  }
  .generate__wrapper__arched {
    width: 1000px;
  }
}

@media only screen and (max-width: 1100px) {
  .generate__wrapper {
    width: 900px;
  }

  .generate__wrapper__arched {
    width: 900px;
  }

  .generateRevenue__ios__mockup {
    width: 310px;
  }

  .joined_phone {
    width: 273px;
  }

  .generateRevenue__content__phone {
    width: 310px;
    height: 500px;
  }

  .generateRevenue__title {
    font-size: 90px;
    line-height: 100px;
  }
}

@media only screen and (max-width: 800px) {
  .generate__wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .generate__wrapper__arched {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 800px) {
  .generateRevenue__content__text {
    width: 100%;
    margin: 0 auto;
  }

  .generateRevenue__title h2 {
    font-size: 80px;
    line-height: 103px;
  }

  .generateRevenue__subtitle {
    width: 501px;
    margin: 0 auto 40px auto;
  }
}

@media only screen and (max-width: 700px) {
  .generateRevenue__content__text {
    width: 336px;
    margin: 0 auto;
  }
  .generateRevenue__subtitle {
    width: auto;
    margin: 0 0 40px 0;
  }
}

@media only screen and (max-width: 670px) {
}

@media only screen and (max-width: 640px) {
  .generate__div__arched {
    padding-bottom: 120px;
  }

  .generate__wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .generate__wrapper__arched {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    padding-top: 100px;
  }
}

@media only screen and (max-width: 590px) {
  .generateRevenue__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 64px;
    text-align: center;
    letter-spacing: 0.02em;
  }
}

@media only screen and (max-width: 520px) {
  .generateRevenue__content__phone .generateRevenue__ios__mockup {
    width: 210px;
  }

  .joined_phone {
    width: 186px;
    left: 13px;
    top: 10px;
  }

  .generateRevenue__content__phone {
    width: 210px;
  }

  .generateRevenue__subtitle {
    font-size: 17px;
    line-height: 29px;
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 420px) {
  .generateRevenue .wrapper__arched {
    padding-top: 100px;
  }

  .generateRevenue.div__arched {
    padding-bottom: 120px;
  }

  .generateRevenue__content__phone__holder {
    width: 210px;
  }

  .generateRevenue__content__phone {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .generateRevenue__content__phone {
    height: 420px;
  }
}
