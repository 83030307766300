.request__wrapper {
  width: 1200px;
  margin: 0 auto;
}

.request__wrapper__arched {
  width: 1200px;
  margin: 0 auto;
  margin-top: -46vw;
  padding-top: 156px;
}

.request__div__arched {
  margin-top: calc(46vw + 300px);
  background-color: #fdfbf7;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  z-index: -1;
  box-sizing: border-box;
  padding-bottom: 293px;
}

.request__div__arched::after {
  content: "";
  width: 92vw;
  height: 92vw;
  border-radius: 50%;
  background: #fdfbf7;
  border: 1px solid #e0e0e0;
  z-index: -2;
  position: absolute;
  top: calc(-46vw + -0.8px);
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 0 50% 0);
}

.request__form {
  width: 328px;
  height: 436px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  margin-top: 280px;
}

.request__form::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 320px;
  height: 436px;
  top: 4px;
  left: 4px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

.request__form::after {
  content: "";
  position: absolute;
  z-index: -2;
  width: 312px;
  height: 436px;
  top: 8px;
  left: 8px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

/*

    .request__input {
    width: 283px;
    height: 52px;
    position: relative;
    background: #FDFBF7;
    margin-top: 34px;
  }
  
  .request__input input{
    width: 283px;
    height: 52px;
    background: none;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    padding-left: 17px;
  
  
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18.6667px;
    line-height: 25px;
  
  
    color: #666666;
  }
  
  .request__input input:focus-visible  {  
    outline: none;
  }
  
  .request__input label{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #666666;
    pointer-events: none;
    transform: translate(16px, 16px);
    transform-origin: left;
    transition: 0.3s ease;
    
  }

  .label.Active  {
    transform: translate(20px, -10px) scale(0.75);
  }

  .fieldset {
    position: absolute;
    top: 0px;
    left: -2px;
    width: 283px;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #999999;
    border-bottom-color: #000000;
    border-radius: 2px;
    pointer-events: none;
    padding: 0 0 0 15px;
  }
  
  .legend {
    width: 0;
    padding: 0;
    transition: 0.3s ease;
  }
  
  .legend.Active {
    width: 66px;
    padding: 0;
  }
  
  .legend.email.Active {
    width: 64px;
    padding: 0;
  }
  
  .legend.expertise.Active {
    width: 88px;
    padding: 0;
  }

  */

.RequestInvite__holder {
  background: transparent;
  padding-bottom: 300px;
}

.intelligent {
  display: flex;
  justify-content: space-between;
}

.intelligent__text {
  width: 556px;
}

.intelligent__text h2 {
  font-family: Schnyder L;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 68px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 120px;
  margin-bottom: 32px;
}

.intelligent__text p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #666666;
  margin-bottom: 40px;
}

.intelligent__text p:last-child {
  margin-bottom: 0;
}

.intelligent__phone {
  width: 345px;
}

.intelligent__phone_imgs {
  position: relative;
  transform: translateY(-100px);
}

.intelligent__phone_imgs img {
  position: absolute;
}

.phone_mockup {
  z-index: 3;
  top: 0;
  left: 0;
}

.the-scroll_phone {
  z-index: 1;
  top: 20px;
  left: 20px;
  width: 310px;
}

.sarah_post {
  position: absolute;
  top: 156px;
  left: -26px;
  z-index: 5;
  width: 310px;
}

.greg_post {
  position: absolute;
  top: 371px;
  left: -62px;
  z-index: 5;
  width: 310px;
}

.request {
  display: grid;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.request-form {
  display: flex;
  flex-direction: column;
}

.nom-de-plume {
  margin-top: 16px;
}

.nom-de-plume a {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #999999;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
}

.nom-de-plume a:hover {
  color: #3d8f44;
}

#nom_mask {
  margin-right: 8px;
}

#nom_mask path {
  transition: 0.3s ease;
}

.nom-de-plume:hover #nom_mask path {
  fill: #3d8f44;
}

.request__form__wrapper {
  width: 283px;
}
.request__title p {
  font-family: Schnyder L;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 68px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: center;
  margin-top: 80px;
}

.request__holder {
  height: 1090px;
  width: 600px;
  background: url("../../images/Coat_of_Arms.png");
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;

  display: grid;
  justify-content: center;
  align-content: center;
}

.request-to-join-btn {
  width: 328px;
  height: 52px;
  background: #afb6b0;
  border-radius: 2px;
  border: none;
  position: relative;
  transition: 0.3s ease;
}

.request-to-join-btn.clickable:hover {
  transform: translateY(4px) scale(0.971);
}

.request-to-join-btn.non__clickable {
  pointer-events: none;
}

.request-to-join-btn.clickable {
  pointer-events: all;
  background: #3d8f44;
}

.request-to-join-btn.clickable::after {
  border-color: #3d8f44;
}

.requset__submit::before {
  content: "";
  background1: linear-gradient(
    rgba(253, 251, 247, 1),
    rgba(253, 251, 247, 1) 36%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 252px;
  position: absolute;
  top: -50px;
  left: 0;
  z-index: -3;
}

.requset__submit {
  margin-top: 44px;
  width: 320px;
  height: 52px;
  position: relative;
}

.requset__submit::after {
  content: "";
  background: #fdfbf7;
  width: 320px;
  height: 52px;
  border: 1px solid #afb6b0;
  box-sizing: border-box;
  border-radius: 2px;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: -1;
}

.requset__submit.clickable::after {
  border-color: #3d8f44;
}

.request-to-join-btn span {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 52px;
  margin: 0;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

.expertise__note {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;
  color: #999999;
}

.fieldset {
  transition: 0.3s ease;
}

.fieldset.valid {
  border-bottom-color: #3d8f44;
}

.fieldset.not_valid {
  border-bottom-color: #e37158;
}
/*
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-bottom-color: black !important;
}

.MuiOutlinedInput-notchedOutline {
    border:1px solid #999999 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #999999 !important;
}
*/
@media only screen and (min-width: 1550px) {
  .request__wrapper {
    width: 1320px;
    margin: 0 auto;
  }

  .intelligent__text h2 {
    font-size: 70px;
    line-height: 79px;
    margin-bottom: 32px;
    margin-top: 120px;
  }

  .intelligent__text p {
    font-size: 23px;
    line-height: 37px;
    margin-bottom: 32px;
  }

  .intelligent__phone_imgs {
    transform: translateY(-110px);
  }

  .intelligent__phone_imgs .phone_mockup {
    width: 404px;
    height: 819px;
  }

  .the-scroll_phone {
    width: 360px;
  }

  .sarah_post {
    width: 358px;
    top: 193px;
  }

  .greg_post {
    width: 358px;
    top: 478px;
    left: -84px;
  }

  .request__title p {
    font-size: 70px;
    line-height: 79px;
    margin-top: 200px;
    margin-bottom: 52px;
  }
}

@media only screen and (max-width: 1300px) {
  .request__wrapper {
    width: 1000px;
  }
}

@media only screen and (max-width: 1200px) {
  .intelligent__text {
    width: 456px;
  }
}

@media only screen and (max-width: 1100px) {
  .RequestInvite__holder .wrapper {
    width: 100%;
    padding: 0 40px 0 40px;
    box-sizing: border-box;
  }

  .intelligent__phone {
    width: 310px;
  }

  .request__wrapper {
    width: 900px;
  }

  .RequestInvite__holder {
    padding-bottom: 220px;
  }
  .intelligent__text {
    width: 406px;
  }

  .intelligent__text h2 {
    margin-top: 80px;
  }

  .request__title p {
    margin-top: 140px;
  }

  .sarah_post {
    top: 126px;
  }

  .greg_post {
    top: 331px;
  }
}

@media only screen and (max-width: 1100px) {
  .intelligent__phone_imgs {
    width: 310px;
  }

  .intelligent__phone_imgs img {
    width: 100%;
  }

  .intelligent__phone_imgs .the-scroll_phone {
    width: 276px;
    top: 15px;
    left: 18px;
  }
}

@media only screen and (max-width: 950px) {
  .request__wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .intelligent__phone {
    grid-area: up;
  }
  .intelligent__text {
    grid-area: down;
  }

  .intelligent {
    display: grid;
    grid-template-rows: 625px 1fr;
    justify-items: center;
    justify-content: center;

    grid-template-areas:
      "up"
      "down";
  }

  .intelligent__phone {
    height: 650px;
    align-self: center;
  }

  .intelligent__phone_imgs {
    transform: translateY(-72px);
  }

  .intelligent__text h2 {
    margin-top: 0;
  }

  .intelligent__phone {
    width: 310px;
  }

  .intelligent__text {
    width: 520px;
  }

  .intelligent__text {
    align-self: center;
  }
}

@media only screen and (max-width: 670px) {
  /*
    .intelligent__phone_imgs{
        width: 250px;
        height: 504px;
    }

    .intelligent__phone {
        height: 504px;
        width: 250px;
    }

    .intelligent__phone_imgs .the-scroll_phone {
        width: 220px;
        left: 16px;
    }

    .sarah_post, .greg_post {
        width: 220px;
    }

    .sarah_post {
        top: 100px;
    }

    .greg_post {
        top: 273px;
        left: -54px;
    }

    .intelligent {
        grid-template-rows: 504px 1fr;
    }

    */
}

@media only screen and (max-width: 640px) {
  .request__wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 570px) {
  .intelligent__text {
    width: 100%;
  }
}

@media only screen and (max-width: 520px) {
  .intelligent__phone {
    width: 259px;
  }

  .RequestInvite__holder .wrapper {
    padding: 0 20px 0 20px;
  }

  .intelligent__phone {
    height: 504px;
  }

  .intelligent__text p {
    font-size: 17px;
    line-height: 29px;
    margin-bottom: 17px;
  }

  .intelligent__phone_imgs {
    width: 250px;
    height: 504px;
  }

  .intelligent__phone {
    height: 504px;
    width: 250px;
  }

  .intelligent__phone_imgs .the-scroll_phone {
    width: 222px;
    left: 14px;
    top: 10px;
  }

  .sarah_post,
  .greg_post {
    width: 220px;
  }

  .sarah_post {
    top: 100px;
  }

  .greg_post {
    top: 273px;
    left: -54px;
  }

  .intelligent {
    grid-template-rows: 504px 1fr;
  }
}

@media only screen and (max-width: 420px) {
  .intelligent__text h2 {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-top: 0;
    margin-bottom: 32px;
    color: #000000;
  }

  .request__title p {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #000000;
  }

  .request__holder {
    background-size: 463px 839px;
    background-position: center;
  }

  .request__form {
    margin-top: 338px !important;
  }

  .intelligent__phone {
    display: flex;
    justify-content: center;
  }

  .request__title p {
    margin-top: 100px;
  }

  .request {
    grid-auto-rows: 150px 840px;
    grid-row-gap: 38px;
  }

  .request__holder {
    width: 100vw;
    height: auto;
  }
}
