
.cc__wrapper__arched {
  width: 1200px;
  margin: 0 auto;
 margin-top: -46vw;
  padding-top: 156px;
  display: grid;
  justify-content: center;
}

.cc__div__arched {
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  z-index: -1;
  box-sizing: border-box;
  padding-bottom: 270px;

  margin-top: 0;
  z-index: 1;
  background: #F5F4F0;
  padding-bottom: 100px;
}

.cc__div__arched::after {
  content: "";
  width: 92vw;
  height: 92vw;
  border-radius: 50%;
  background: #F5F4F0;
  border: 1px solid #E0E0E0;
  z-index: -2;
  position: absolute;
  top:  calc(-46vw + 0px);
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 0 50% 0);
}

.createC__background {
    height: calc(46vw + 144px);
    background: url("../images/c_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.createC .wrapper__arched {
    display: grid;
    justify-content: center;
}

.createC__headline {
    width: 692px;
}

.createC__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 113px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 40px;
}

.createC__main__subtitle{
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #666666;
}

.createC__forms {
   width: 660px;
}

.createC__subtitle {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #000000;
    position: relative;
    margin-top: 60px;
}

.c_a_1 {
  opacity: 0;
  animation: 0.7s rollout forwards 1s;
}

.c_a_2 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.2s;
}

.c_a_3 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.4s;
}

.c_a_4 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.6s;
}

.c_a_5 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.8s;
}

@keyframes rollout {
  0% {
    transform: translateY(100px) rotate(2deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}


.createC__subtitle::after {
   content: "";
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 136px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #3D8F44;
    opacity: 0.05;
    position: absolute;
}

.intro__title::after {
    content: "01";
    top: -56px;
    left: -152px;
}


.community__title::after {
    content: "02";
    top: -56px;
    left: -176px;
}

.community__title {
    margin-bottom: 16px;
}

.co-founders__title::after {
    content: "03";
    top: -56px;
    left: -184px;
}

.column-information__title::after {
    content: "04";
    top: -56px;
    left: -194px;
}


.c_main_inputs, .c_main_inputs_second {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.c_main_inputs_second {
  margin-top: 30px;
}

.community__subtitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #666666;
    margin-top: 0px;
    margin-bottom: 0;
}

.community, .co-founder {
margin-top: 28px;
border-bottom: 1px solid #e0e0e0;
padding-bottom: 28px;
}

.remove-field {
    width: 312px;
    height: 58px;
    border: 1px solid #BDBDBD;
    border-radius: 4px; 
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s ease!important;
}

.remove-field:hover {
  border: 1px solid #EB5757;
}

.remove-field p {
    margin: 0;
    line-height: 58px;
    text-align: center;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #EB5757;
}

.community__source, .co-founder__info, .column__information {
    display: flex;
    justify-content: space-between;
}

.community__link, .co-founder__link {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.add__another__community {
    width: 312px;
    height: 58px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    margin-top: 28px;
    cursor: pointer;
    transition: 0.3s ease;
}

.add__another__community:hover {
   border: 1px solid #3D8F44;

}

.add__another__community__inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.add__another__community__inner p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #3D8F44;
    margin: 0 0 0 12px;
    line-height: 58px;
}

.plan__container {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;
    height: 52px;
}


/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 64px;
  }

  .container span {
    font-size: 22px;
    line-height: 26px;
  }

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 24px;
    border: 1px solid #3D8F44;
    box-sizing: border-box;
    border-radius: 14px;
  }

  .checkmark__inside {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 14px;
    background: #3D8F44;
    z-index: -1;
    transform: scale(0.0);
    transition: 0.15s ease;
  }
  
  /* On mouse-over, add a grey background color */
 
  
  /* When the radio button is checked, add a blue background */
  .container input:checked + .checkmark__inside {
   transform: scale(1.0);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: url('../images/Check_radio.svg');;
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: -3px;
    left: 6px;

  }

  .createC__plan {
    display: flex;
    align-items: center;
  }

  .plan__radio {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    
    /* Almost Black */
    
    color: #333333;

  }

  .createC__submit__button.not_validate {
    width: 312px;
    height: 60px;
    background: #AFB6B0;
    border: none;
    outline: none;

    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    box-sizing: border-box;
    pointer-events: none;
  }

  .createC__submit__button.validate {
    width: 312px;
    height: 60px;
    background: #3D8F44;
    border: none;
    outline: none;

    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s ease;
    pointer-events: all;
  }

  .createC__submit__button:hover{
    transform: translate(4px, 4px);
  }

  .createC__submit {
    width: 312px;
    height: 60px;
    position: relative;
    margin-top: 60px;
    pointer-events: none;
  }

  .createC__submit.validate {
   
    cursor: pointer;
  }

  .createC__submit:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 4px;
      left: 4px;
      border:1px solid #3D8F44;
      border-radius: 2px;
      box-sizing: border-box;
  }

  .createC__submit.validate::after {
    border:1px solid #3D8F44;
  }

  .createC__submit.not_validate::after {
    border:1px solid #AFB6B0;
  }

  @media only screen and (min-width: 1550px){
      .createC__title {
        font-size: 117px;
        line-height: 132px;
    }

    .createC__headline {
      width: 800px;
    }

    .createC__main__subtitle{
      font-size: 23px;
      line-height: 37px;
      width: 700px;
      width: auto;
  }

    .createC__forms {
      width: 753px;
    }

    .remove-field {
      width: 362px;
    }

    .createC__submit, .createC__submit__button.not_validate, .createC__submit__button.validate, .add__another__community {
      width: 362px;
    }
   
  }


  @media only screen and (max-width: 1300px){
    .cc__wrapper__arched {
      width: 1100px;
    }
  }

 

  @media only screen and (max-width: 1250px){

    .intro__title::after, .co-founders__title::after, .column-information__title::after {
        left: auto;
        right: 0;
        top: -65px;
    }

    .community__title::after {
        left: auto;
        right: 0;
        top: -27px;
    }

  }

  @media only screen and (max-width: 1100px){
    .cc__wrapper__arched {
      width: 900px;
    }
  }

  @media only screen and (max-width: 950px){
    .cc__wrapper__arched {
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      box-sizing: border-box;
      }
  }

  @media only screen and (max-width: 800px){
    .createC__title{
        font-size: 80px;
        line-height: 93px;
    }

    .createC__headline {
        width: 90%;
        margin: 0 auto;
    }

  }

  @media only screen and (max-width: 730px){
    .c_main_inputs, 
    .community__source,
    .co-founder__info,
    .column__information,
    .c_main_inputs_second 
    {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 34px;
    }

    .community__link,
    .co-founder__link{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;

    }

    .plan__container{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 58px;
      grid-gap: 34px;
    }

    .remove-field {
      margin-top: 36px;
    }

    .createC__forms {
        width: 50%;
        margin: 0 auto;
    }

    .plan__container {
        height: auto;
        margin-bottom: 34px;
    }

  }

  @media only screen and (max-width: 640px){
    .cc__wrapper__arched {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      }
  }

  @media only screen and (max-width: 600px){
    .createC__title {
        font-size: 66px;
        line-height: 66px;
    }

    .createC__main__subtitle {
        font-size: 18px;
        line-height: 28px;
    }

    .add__another__community, .remove-field {
      width: 100%;
    }

  }



  @media only screen and (max-width: 520px){
    .createC__forms {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }


    .createC__title {
        line-height: 36px;
        font-size: 36px;
        margin-bottom: 20px;
    }

    .createC__main__subtitle {
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
         width: 282px;
    }

    .cc__wrapper__arched {
      padding-top: 80px;
    }
  }
 