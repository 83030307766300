.go-back {
    position: fixed;
    top: 200px;
    left: 32px;
    cursor: pointer;
    pointer-events: all;
 }

 @media only screen and (min-width: 1500px){
    .go-back {
        top: 180px;
        left: 60px;
     }
    
 }

 @media only screen and (max-width: 1200px){
     
    .go-back {
       position: unset; 
     }

 }