.love__wrapper {
    width: 1131px;
    margin: 0 auto;
    padding-bottom: 160px;
  }

  .love__headline {
      margin-top: 160px;
      margin-bottom: 90px;
  }

  .love__card__holder {
    width: 1131px;
    margin: 0 auto;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -14px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 14px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 16px;
  }


.love__headline h1 {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 113px;
    letter-spacing: 0.5px;
    color: #3D8F44;
    margin: 0;
    width: 90%;
}

.love__card {
    width: 362px;
    height: auto;
    background: #FDFBF7;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 2px;
 
    position: relative;
    padding: 28px;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
}

.love__card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #FDFBF7;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;

}

.love__card a {
    text-decoration: none;
  
}

.love__card__content ul {
    padding-left: 20px;
}

.love__card__content ul li{
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    /* or 32px */ 
    color: #282828;
}

.love__card__content a {
    color: #3D8F44;
}

.love__card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.love__card__user {
    display: flex;
    align-items: center;
    gap: 16px;
}

.love__card__avatar {
    width: 40px;
    height: 50px;
    border-radius: 20px;
    overflow: hidden;
}

.love__card__avatar img {
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
}

.love__card__info h2{
    margin: 0;  
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #282828;
}

.love__card__info p{
    margin: 0;

    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #282828;
}

.love__card__content {
    margin-top: 34px;
}

.love__card__content p{
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    margin: 0;
    
    
    color: #282828;
}

@media only screen and (max-width: 1200px){
    .love__card__holder {
        width: 740px;
    }

    .love__wrapper {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 810px){

    .love__headline h1{
        font-size: 80px;
        line-height: 93px;
        width: 95%;
    }

    .love__wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px; 
     }

    .love__headline {
        margin-top: 130px;
        margin-bottom: 60px;
    }

    .love__card__holder {
        width: 700px;
    } 

    .love__card {
        width: 342px;
    }
}

@media only screen and (max-width: 740px){
    .love__card__holder {
        width: 362px;
    }

    .love__card {
        width: 362px;
    }
}


@media only screen and (max-width: 600px){

    .love__headline h1{
        font-size: 66px;
        line-height: 66px;
    } 

    .love__wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 100px;
    }

}

@media only screen and (max-width: 520px){
    .love__headline h1{
        line-height: 36px;
        font-size: 36px;
    }

    .love__headline {
        margin-top: 100px;
        margin-bottom: 60px;
    }

    .love__card,.love__card__holder {
        width: 342px;
    }
}

@media only screen and (max-width: 380px) {

    .love__card,.love__card__holder {
        width: 322px;
    }

    .love__headline h1 {
        width: 95%;
    }

}