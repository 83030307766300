.footer {
    padding-bottom: 90px;
    padding-top: 124px;
    position: relative;
}

.footer__wrapper {
    width: 1200px;
    margin: 0 auto;
  }

.footer::before {
    content: "";
    width: 100%;
    height: 22px;
    background: url('../images/Pattern.png') ;
    background-repeat: repeat-x;
    background-position: bottom;
    position: absolute;
    top: -22px;
    left: 0;
    border-top: 1px dashed #3D8F44;
    z-index: 1;

}

.footer__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 80px;
    margin-top: 0;
}

.footer__call-to-action {
    padding-bottom: 80px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 100px;

}

.footer__btns {
    display: flex;
}

.footer__content {
    display: flex;
    justify-content: space-between;
}

.apps p{
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 0;
}

.android-logo {
   margin-left: 32px;
}

.footer__link__holder {
    display: flex;
}

.footer__link__holder a {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #3D8F44;
    text-decoration: none;
    margin-left: 52px;
    display: inline-block;
    transition: 0.3s ease;
}

.copyrights  {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #999999;
    transition: 0.3s ease;
}

.footer__link__animatio a:hover {
text-decoration: underline;
  
}

@media only screen and (min-width: 1550px){

    .footer__wrapper {
        width: 1320px;
        margin: 0 auto;
      }

    .footer__title {
        font-size: 70px;
        line-height: 79px
    }

    .apps p {
        font-size: 16px;
        line-height: 22px;
    }

    .footer__link__holder a {
        font-size: 16px;
        line-height: 22px;
    }

    .copyrights{
        font-size: 18px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1300px){

    .footer__wrapper {
        width: 1100px;
      }
}

@media only screen and (max-width: 1100px){

    .footer__wrapper {
        width: 900px;
      }

      .footer {
        padding-bottom: 90px;
        padding-top: 90px;
      }

      .footer__call-to-action {
        padding-bottom: 60px;
        border-bottom: 1px solid #E0E0E0;
        margin-bottom: 60px;
      }


}

@media only screen and (max-width: 950px){
    .footer__wrapper {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
      }

    .footer__content {
        flex-direction: column;
    }

    .apps p {
        text-align: left;
    }

    .copyrights {
        text-align: left;
    }

    .footer__link {
        margin-top: 16px;
    }

    .footer__link__holder a {
        margin-right: 52px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 700px){
    .footer__link__holder {
        display: grid;
        grid-template-columns: 120px 120px;
        grid-template-rows: 1fr 1fr;
        grid-column-gap:44px ;
        grid-row-gap:32px ;
        margin-top: 54px;
        margin-bottom: 54px;
    }

    .footer__link__holder a {
        margin-right: 0;
        justify-self: start;
        
    }

    .footer__title {
        width: 60%;
    }
}

@media only screen and (max-width: 640px){
    .footer__wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }

}




@media only screen and (max-width: 600px){
    .footer__title {
        width: 80%;
    }
}

@media only screen and (max-width: 520px){
    

    .footer__call-to-action .request__btn, .footer__call-to-action .RequestBtn {
        width: 100%;
    }

    .footer__call-to-action .request__btn::after {
        width: 100%;
    }

    .footer__call-to-action .LoginBtn, .footer__call-to-action .login__btn {
        width: 100%;
    }

    .footer__call-to-action .login__btn::after {
        width: 100%;
    }

    .footer__btns {

        flex-direction: column;
        gap: 10px;
    }

    .copyrights {
        margin-bottom: 0;
    }

    .footer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .footer__link__holder {
        grid-row-gap:20px;
    }


}

@media only screen and (max-width: 420px){
    

    .footer__title {
        font-family: Schnyder M;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 58px;
        letter-spacing: 0.02em;
        color: #000000;
        width: 100%;
        margin-bottom: 60px;
    }

    .footer__call-to-action {
        margin-bottom: 32px;
        padding-bottom: 32px;
    }

    .footer__link__holder{
        margin-top: 0;
        margin-bottom: 32px;
       
    }

    .footer__link__holder > div{
       width: 125px;
       
    }

    .footer__link {
        margin-top: 28px;
    }

    .footer__wrapper {
        height: 594px;
        overflow:hidden ;
    }

}









