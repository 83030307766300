.menuContent-container {
    background: url('../images/noise.png'), #F5F4F0;
    transform: translateY(-73px);
    display: grid;
    height: 0vh;
    overflow: hidden;
    transition: all 0.5s ease 0.5s;
    pointer-events: all;
}

.menuContent-container.show {
    height: 100vh;
    transition: all 0.6s ease;
}


.menuContent {
    width: 1132px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.menuContent__links {
    margin-left: 60px;
}


.link__container {
    display: flex;
    position: relative;
}

.link__container img {
    position: absolute;
    top: 45%;
    left: -100px;
    opacity: 0;
    transition: 0.3s ease;
}

.menuContent__links a {
    text-decoration: none;
}

.menuContent__links a:hover .link__container img {
    left: -80px;
    opacity: 1;
}

.menuContent__links a p {
    font-family: Schnyder L;
    font-style: normal;
    font-size: 120px;
    line-height: 123px;
    letter-spacing: 0.5px;
    color: #999999;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 0;
}

.menuContent__links a.selected p {
   color: #3D8F44;
}
  .menuContent__link:nth-of-type(1) {
    opacity: 0;
    transform: translateX(30px);
    transition:all 0.3s ease 0.3s;
 }

 .menuContent__link:nth-of-type(2) {
   
    opacity: 0;
    transform: translateX(30px);
    transition:all 0.3s ease 0.2s;
 }

 .menuContent__link:nth-of-type(3) {
    opacity: 0;
    transform: translateX(30px);
    transition:all 0.3s ease 0.1s;
 }

 .menuContent__link:nth-of-type(4) {
    opacity: 0;
    transform: translateX(30px);
    transition:all 0.3s ease 0s ;
 }
 
  .menuContent__link.show:nth-of-type(1) {
   opacity: 1;
   transform: translateX(0);
   transition:all 0.3s ease 0.5s;
 }

 .menuContent__link.show:nth-of-type(2) {
    opacity: 1;
    transform: translateX(0);
    transition:all 0.3s ease 0.6s;
 }

 .menuContent__link.show:nth-of-type(3) {
    opacity: 1;
    transform: translateX(0);
    transition:all 0.3s ease 0.7s;
 }

 .menuContent__link.show:nth-of-type(4) {
    opacity: 1;
    transform: translateX(0);
    transition:all 0.3s ease 0.8s;
 }

.menuContent__links__text {
    margin-top: 0;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #999999;
    opacity: 0.6;
    margin-bottom: 52px;
}

.last__link {
    margin-bottom: 0;
}

.menuContent__image {
    display: grid;
    align-items: end;
    justify-content: end;
    height: 100vh;
}

.menuContent__image img {
    border-radius:300px 300px 0px 0px;
    width: 364px;
}

.selected {
    background-color: red;
}

@media only screen and (min-width: 1550px){
    .menuContent__links a p {
        font-size: 130px;
    }

    .menuContent__links__text {
        margin-bottom: 80px;
        font-weight: 500;
        margin-top: 10px;
        font-size: 20px;
    }

    .menuContent__image img {
        width: 450px;
    }

    .last__link {
        margin-bottom: 0;
    }
}



@media only screen and (max-width: 1200px){
    .menuContent__links {
        width: 550px;
    }

    .menuContent{
        width: 900px;
    }

    .menuContent-container {
        transform: translateY(-113px);
      }
}

@media only screen and (max-width: 1100px){
    .menuContent__links a p {
        font-size: 120px;
    }

    .menuContent__links__text {
        margin-bottom: 92px;
        font-size: 20px;
        font-weight: 500;
        margin-top: 16px;
    }

    .record__text {
        margin-bottom: 0;
    }

    .menuContent__links {
        width: 600px;
    }

    .menuContent__image img {
        width: 450px;
    }
}

@media only screen and (max-width: 1000px){
    .menuContent__links a p {
        font-size: 100px;
        line-height: 93px;
    }

    .menuContent__links {
        width: 480px;
    }

    .link__container img {
        display: none;
    }
}

@media only screen and (max-width: 950px){
    .menuContent {
        width: 100%;
    }

    .menuContent__links {
        justify-self: flex-end;
    }

}

@media only screen and (max-width: 770px){
    .menuContent-container {
        transform: translateY(-113px);
      }

}



@media only screen and (max-width: 730px){
    .menuContent__image img{
        transform: translateX(50%);
    }

    .menuContent {
        grid-template-columns: 1fr 270px;
    }

}

@media only screen and (max-width: 630px) {

    .menuContent__links a p {
        font-family: Schnyder L;
        font-style: normal;
        font-weight: 600;
        font-size: 62px;
        line-height: 70px;
        letter-spacing: 0.5px;
    }

    .menuContent__links__text {
        margin-bottom: 40px;
        margin-top: 5px;
        font-size: 16px;
        line-height: 22px;
    }

    .menuContent__links {
        width: 250px;
    }

    .menuContent__image img {
        width: 364px;
        transform: translateX(-274px);
    }

    .record__text {
        margin-bottom: 0;
    }
  

    .menuContent {
        grid-template-columns: 1fr 170px;
    }
   

}

@media only screen and (max-width: 520px) {
   
    .menuContent-container {
        transform: translateY(-93px);
      }

    .menuContent {
        grid-template-columns: 1fr 70px;
    }

    .menuContent__image img{
        transform: translateX(80%);
    }
    .menuContent__links {
        margin-left: 20px;
        justify-self: flex-start;
    }

    .menuContent__links__text {
        width: 80%;
    }
    
}



