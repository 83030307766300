body {
  background: url("images/noise.png"), #f5f4f0;
  /* overflow-x: hidden; */
  width: 100vw;
}

/* body.no-scroll {
  overflow-y: hidden;
 
} */

/*

.wrapper {
  width: 1200px;
  margin: 0 auto;
}

.wrapper__arched {
  width: 1200px;
  margin: 0 auto;
 margin-top: -46vw;
  padding-top: 156px;
}

.div__arched {
  margin-top: calc( 46vw + 300px);
  background-color: #FDFBF7;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  z-index: -1;
  box-sizing: border-box;
  padding-bottom: 270px;
}

.div__arched::after {
  content: "";
  width: 92vw;
  height: 92vw;
  border-radius: 50%;
  background: #FDFBF7;
  border: 1px solid #E0E0E0;
  z-index: -2;
  position: absolute;
  top:  calc(-46vw + -0.8px);
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 0 50% 0);
}





.request__input {
  width: 283px;
  height: 52px;
  position: relative;
  background: #FDFBF7;
  margin-top: 34px;
}

.request__input input{
  width: 283px;
  height: 52px;
  background: none;
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 17px;


  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 18.6667px;
  line-height: 25px;


  color: #666666;
}

.request__input input:focus-visible  {  
  outline: none;
}

.request__input label{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #666666;
  pointer-events: none;
  transform: translate(16px, 16px);
  transform-origin: left;
  transition: 0.3s ease;
  
}


.label.Active  {
  transform: translate(20px, -10px) scale(0.75);
}

.request__form {
  width: 328px;
  height: 436px;
  background: #F5F4F0;
  border: 1px solid #000000;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  margin-top: 280px;
}

.request__form::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 320px;
  height: 436px;
  top: 4px;
  left: 4px;
  background: #EDECE8;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

.request__form::after {
  content: "";
  position: absolute;
  z-index: -2;
  width: 312px;
  height: 436px;
  top: 8px;
  left: 8px;
  background: #EDECE8;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

.fieldset {
  position: absolute;
  top: 0px;
  left: -2px;
  width: 283px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #999999;
  border-bottom-color: #000000;
  border-radius: 2px;
  pointer-events: none;
  padding: 0 0 0 15px;
}

.legend {
  width: 0;
  padding: 0;
  transition: 0.3s ease;
}

.legend.Active {
  width: 66px;
  padding: 0;
}

.legend.email.Active {
  width: 64px;
  padding: 0;
}

.legend.expertise.Active {
  width: 88px;
  padding: 0;
}


@media only screen and (min-width: 1550px){
    
  .wrapper {
      width: 1320px;
  } 

  .wrapper__arched {
    padding-top: 182px;
}


}


@media only screen and (max-width: 1300px){

  .wrapper {
    width: 1000px;
  }
  
  .wrapper__arched {
    width: 1000px;
  }

}

@media only screen and (max-width: 1100px){

  .wrapper {
    width: 900px;
  }
  
  .wrapper__arched {
    width: 900px;
  }

}

@media only screen and (max-width: 950px){

  .wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }
  
  .wrapper__arched {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

}

@media only screen and (max-width: 640px){
  .wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  
  .wrapper__arched {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

input,
textarea,
button,
select,
    a {
      
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }


}


*/
