.trending {
  margin-top: 120px;
}

.trending__wrapper {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.trending__wrapper__inner {
  display: flex;
  align-items: baseline;
}

.trending__link {
  font-family: Schnyder S;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #3d8f44;
  text-decoration: none;
}

.trending__link:hover {
  text-decoration: underline;
}

.trending__carousel__holder {
  width: 1200px;
  margin: 0 auto;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.left-arrow,
.right-arrow {
  width: 60px;
  height: 60px;
  background: #fdfbf7;
  border-radius: 50%;
  border: 1px solid #999999;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  align-content: center;
  z-index: 90;
}

.left-arrow {
  position: absolute;
  top: 190px;
  left: -30px;
  transform: scale(0);
  transition: 0.3s ease;
}

.right-arrow {
  position: absolute;
  top: 190px;
  right: -30px;
  transform: scale(0);
  transition: 0.3s ease;
}

.right-arrow.show {
  transform: scale(1);
}

.left-arrow.show {
  transform: scale(1);
}

.trending__wrapper.arrow-holder {
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.trending__carousel__holder::-webkit-scrollbar {
  display: none;
}

.trending__title {
  font-family: Schnyder M;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #000000;
  margin: 0;
}

.trending__subtitle {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #999999;
  margin: 0 0 0 45px;
}

.trending__carousel {
  padding-left: 0;
  padding-top: 60px;
  padding-bottom: 16px;
  display: flex;
  width: auto;
  gap: 24px;
}

.carousel__item {
  width: 300px;
  height: fit-content;
  min-height: 1px;
  background: #fdfbf7;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;

  position: relative;
  padding: 32px 24px 24px 24px;
}

.carousel__item::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 4px;
  left: 4px;
  background: #fdfbf7;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

.carousel__item:before {
  content: "";
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  top: 7px;
  left: 7px;
  background: #fdfbf7;
  border: 1px solid #000000;
  border-radius: 2px;
  box-sizing: border-box;
}

.postHeader {
  font-family: Schnyder M;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #000000;
  margin: 0;
}

.underline {
  transform: translate(-10px, -15px);
}

.postContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.postNumbers p {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  margin: 0;
}

.avatarRowContainer {
  display: flex;
  flex-direction: row-reverse;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarContainer > * {
  margin-right: 4px;
}

.authorName {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.authorUsername {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.avatarImage {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 100%;
}

.postActions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 4px;
}

.votes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.postActions > img,
.votes > img {
  width: 16px;
  margin-right: 4px;
}

.votes > p {
  margin-right: 4px;
}

.carousel__description {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  margin-bottom: 0;
  margin-top: 5px;
}

.JoinButton {
  width: 255px;
  height: 40px;
  background: #000000;
  border-radius: 2px;
  padding: 0;
  border: none;
  margin-top: 24px;
}

.JoinButton span {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (min-width: 1550px) {
  .left-arrow,
  .right-arrow {
    top: 208px;
  }

  .trending__carousel__holder {
    width: 1320px;
  }

  .trending__wrapper {
    width: 1320px;
    margin: 0 auto;
  }

  .trending__title {
    font-size: 37px;
    line-height: 42px;
  }

  .trending__subtitle {
    font-size: 16.3349px;
    line-height: 22px;
  }

  .carousel__item {
    width: 353px;
  }

  .postHeader {
    font-size: 37px;
    line-height: 42px;
  }

  .postNumbers p {
    font-size: 16px;
    line-height: 22px;
  }

  .carousel__description {
    font-size: 16px;
    line-height: 26px;
  }

  .JoinButton {
    width: 297px;
    height: 46px;
  }

  .JoinButton span {
    font-size: 14px;
    line-height: 23px;
  }

  .avatarImage {
    width: 37px;
    height: 37px;
  }
}

@media only screen and (max-width: 1300px) {
  .trending__wrapper {
    width: 1000px;
  }

  .trending__carousel__holder {
    width: 1000px;
  }
}

@media only screen and (max-width: 1100px) {
  .trending__carousel__holder {
    width: 900px;
  }
}

@media only screen and (max-width: 1100px) {
  .trending__wrapper {
    width: 900px;
  }
}

@media only screen and (max-width: 950px) {
  .trending__wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .trending__animation:last-child > .carousel__item {
    margin-right: 50px;
  }

  .trending__carousel {
    padding-left: 50px;
  }

  .trending__carousel__holder {
    width: 100%;
  }

  .trending__wrapper.arrow-holder {
    display: none;
  }

  .trending__wrapper__inner {
    flex-direction: column;
  }

  .trending__subtitle {
    margin-left: 0;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 770px) {
  .trending {
    margin-top: 60px;
  }

  .trending__carousel {
    padding-top: 28px;
  }
}

@media only screen and (max-width: 750px) {
  .trending__wrapper {
    flex-direction: column;
  }

  .trending__subtitle {
    margin-left: 0;
    margin-top: 12px;
  }
  .trending__link {
    margin-top: 28px;
  }
}

@media only screen and (max-width: 640px) {
  .trending__animation:last-child > .carousel__item {
    margin-right: 20px;
  }
  .trending__carousel {
    padding-left: 20px;
  }

  .trending__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
