.earnReputation {
    border-top: 1px solid #E0E0E0;
}

.earn__wrapper {
    width: 1200px;
    margin: 0 auto;
  }
  
  .earn__wrapper__arched {
    width: 1200px;
    margin: 0 auto;
   margin-top: -46vw;
    padding-top: 156px;
  }
  
  .earn__div__arched {
    margin-top: calc( 46vw + 300px);
    background-color: #FDFBF7;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    z-index: -1;
    box-sizing: border-box;
    padding-bottom: 270px;
  }
  
  .earn__div__arched::after {
    content: "";
    width: 92vw;
    height: 92vw;
    border-radius: 50%;
    background: #FDFBF7;
    border: 1px solid #E0E0E0;
    z-index: -2;
    position: absolute;
    top:  calc(-46vw + -0.8px);
    left: 50%;
    transform: translateX(-50%);
    clip-path: inset(0 0 50% 0);
  }

.earnReputation__image {
  display: grid;
  justify-content: center;
  transform: translateY(-95px);
}
.earnReputation__image img{
   justify-self: center;
}

.earnReputation__content {
   display: grid;
   grid-template-columns: 390px 1fr;
   grid-column-gap: 230px;
}

.phone__container {
    position: relative;
    height: 702px;
    width: 345px;
}

.phones {
    display: grid;
    justify-content: center;
}

.earnReputation__ios__mockup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.profile__phone {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    width: 310px;
}


.earnReputation__text {
    width: 500px;
}


.earnReputation__title {
    font-family: Schnyder L;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 80px;
    margin-bottom: 52px;
}

.earnReputation__paragraph {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #666666;
    margin-bottom: 40px;
}

.earnReputation__subtitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #666666;;
    margin-top: 28px;;
}

.earnReputation__statistics {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto 50px auto;
}

.statistics {
    width: 384px;
    height: 196px;
    background: #FDFBF7;
    border-radius: 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.statistics__up {
    display: grid;
   justify-items: center;
   position: relative
}

.statistics__dash {
    position: absolute;
    left: 0;
    top: 60px;
}

.statistics__icon {
    margin-top: 20px;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #E0E0E0;
    display: grid;
    justify-content: center;
    align-content: center;
    position: relative;
}

.level__indicator {
    position: absolute;
    top: -2px;
    left: -2px;
}

.level__path__writing {
    stroke-dasharray: 100%;
    stroke-dashoffset: 0%;
}



.statistics__level  p{
    font-family: Schnyder S;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 33px;
}

.statistics__level span {
    color: #3D8F44;
}




.earnReputation__bgimage {
  height: calc(46vw + 200px);
  background: url('../../images/c_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.earnReputation__bgimage::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../images/noise.png'), linear-gradient(rgba(245,244,240,01) 0%, rgba(245,244,240,0) 50%, rgba(255,255,255,0) 100%);
    background-repeat: repeat;
}

.level__indicator {
    width: 41px;
    top: 0px;
    height: 80px;
}

.level__indicator__2 {
    left: 6px;
    position: absolute;
    bottom: -3px;
    width: 35px;

}

.level__indicator__3 {
    left: -2px;
    position: absolute;
    width: 40px;
    bottom: -3px;

}

@media only screen and (min-width: 1550px){

    .earnReputation__text {
        width: 610px;
    }


.earn__wrapper {
    width: 1320px;
    margin: 0 auto;
  }
    .level__indicator {
        position: absolute;
        width: 49px;
        top: 2px;
        left: -3px;
        height: 89px;

    }


     .level__indicator__2{
        position: absolute;
        bottom: -2px;
        left: 9px;
        height: 18px;
     }

     .level__indicator__3 {
        position: absolute;
        top: -2px;
        left: -4px;
        width: 50px;
        height: 93px;
     }

     .statistics__dash {
        top: 65px;
     }

    .earnReputation__content {
        grid-template-columns: 404px 1fr;
    }
    .earnReputation__title {
        font-size: 70px;
        line-height: 79px;
        margin-bottom: 32px;
    }

    .earnReputation__paragraph {
        font-size: 23px;
        line-height: 37px;
        margin-bottom: 32px;
    }
    .earnReputation__ios__mockup, .phone__container {
        width: 404px;
        height: 819px;
    }

    .profile__phone {
        width: 360px;
        left: 22px;
    }

    .earnReputation__subtitle {
        font-size: 21px;
        line-height: 29px;
        width: 110%;
        margin-left: -18px;
    }

    .earnReputation__statistics {
        width: 1400px;
    }

    .statistics{
        width: 448px;
        height: 228px;
        border-radius: 25px;
    }

    .statistics__level p {
        font-size: 28px;
        line-height: 32px;
    }

    .statistics__dash, .statistics__dash path {
        width: 100%;
    }

    .statistics__icon {
        width: 93px;
        height: 93px;
    }
}

@media only screen and (max-width: 1300px){

    .earnReputation__content {
        grid-column-gap: 150px;
    }

    .earn__wrapper {
        width: 1000px;
      }

}


@media only screen and (max-width: 1200px){
    .earnReputation__statistics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      box-sizing: border-box;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }

    .statistics:nth-child(1) {
        justify-self: end;
    }

    .statistics:nth-child(2) {
        justify-self: start;
    }


    .statistics:last-child{
        grid-column: span 2;
        justify-self: center;
    }

    .earnReputation__text {
      overflow: hidden;
    }



    

}

@media only screen and (max-width: 1100px) {

    .earnReputation__text {
        width: 410px;
    }


    .earn__wrapper {
        padding-right: 50px;
        padding-left: 50px;
        box-sizing: border-box;
    
    }

    .earnReputation__content {
        display: grid;
        grid-template-columns: 380px 397px;
        grid-column-gap: 110px;
    }

    .earnReputation__ios__mockup {
        width: 310px;
    }

    .profile__phone  {
        width: 270px;
    }
    .phone__container {
        width: 310px;
        height: 625px;
    }

}




@media only screen and (max-width: 1000px){
    .earnReputation__content {
        grid-column-gap: 100px;
    }

    .earn__wrapper {
        width: 900px;
      }

      .earnReputation__text {
          width: auto;
      }

      .earn__wrapper {
          padding: 0;
      }

}

@media only screen and (max-width: 950px){
    .earnReputation__content {
        display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    }

    .earn__wrapper {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
      }

    .earnReputation__title {
        margin-top: 20px;
    }

    .earnReputation__text{
        width: 456px;
        align-self: center;
    }

    .earnReputation__subtitle {
        width: 400px;
    }

    .earnReputation__statistics {
        padding-left: 0;
        padding-right: 0;
    }

    .earnReputation__bgimage {
        background-position: top center;
    }

    .earnReputation__text {
        width: 520px;
    }
}

@media only screen and (max-width: 850px){
    .earnReputation__statistics {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .statistics:nth-child(1) {
        justify-self: center;
    }

    .statistics:nth-child(2) {
        justify-self: center;
    }


    .statistics:last-child {
        justify-self: center;
        grid-column: auto;
    }
   
}

@media only screen and (max-width: 640px){
    .earn__wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }

}



@media only screen and (max-width: 570px){
    .earnReputation__text {
        width: 100%;
    }
}

@media only screen and (max-width: 520px){
    .phone__container, .earnReputation__ios__mockup {
        width: 210px;
    }
    .phone__container {
        height: 423px;
    }

    .profile__phone {
        width: 187px;
        left: 12px;
        top: 10px;
    }

    .earnReputation__image {
        transform: translateY(-55px);
    }

    .earnReputation__paragraph {
        font-size: 17px;
        line-height: 29px;
        margin-bottom: 17px;
    }


}




@media only screen and (max-width: 420px) {
    .earnReputation__image .earnReputation__ios__mockup {
        width: 250px;
    }

    .profile__phone {
        width: 223px;
        top: 13px;
        left: 13px;
    }


    .phone__container {
        height: 504px;
    }
    .earnReputation__subtitle {
        width: 100%;
    }

    .statistics {
        width: 335px;
        grid-column-gap: 0;
        
    }

    .statistics__dash {
        width: 335px;
    }

    .statistics__subtitle {
        width: 140px;
    }

    #writing-progress {
        width: 239px;
    }

    .statistics__icon img {
        margin-left: 16px;
        margin-top: 20px;
    }

    .earnReputation__title {
        margin-top: 0;
        margin-bottom: 32px;
        width: 100%;
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 54px;
        letter-spacing: 0.02em;
        color: #000000;
    }

    .earnReputation__subtitle {
        margin-bottom: 0;
    }

    .earnReputation__image {
        transform:translateY(-60px);
    }

    .phone__container {
        width: 250px;
    }


}



