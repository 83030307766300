.about {
    padding-bottom: 100px;
}

.about__wrapper {
    width: 1300px;
    margin: 0 auto;
  }


.dispatches {
    position: relative;
}

.about__content .dispatches::before {
    content: "";
    width: 1300px;
    height: 1px;
    background: #E0E0E0;
    position: absolute;
    left: -58%;
    top: -32px;
}


 .team-bio h2, .about__title h2, .about__mision_p {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 56px;
}

.about__content {
    display: grid;
    grid-template-columns: 300px 600px 300px;
    grid-column-gap: 50px;
    margin-top:154px;
}

.team__content__p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
    margin-top: 0;
    margin-bottom: 28px;
}

.author__preview__holder {
  border-bottom: 1px dashed #E0E0E0;
  padding-bottom: 10px;
}

.author__preview {
    display: flex;
    margin-bottom: 16px;
}

.author__info {
    display: grid;
    align-content: center;
    margin-left: 12px;
}

.author__info a {
    text-decoration:none;
}

.author__info h2 {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin:0;
}

.author__info p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #666666;
    margin:0;
}

.hire__link{
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-top:40px ;
}

.hire__link span{
    text-decoration-line: underline;    
    color: #3D8F44;
}

.mission__content p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
    margin-top: 0;
    margin-bottom: 32px;
}

.contact-us-btn {
    width: 272px;
    height: 48px;
    box-sizing: border-box;
    background:#FDFBF7;
    border: 1px solid #000000;
    border-radius: 2px;
}

.contact-us-btn span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 48px;
    margin:0;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
}

.request-invite-btn {
    width: 272px;
    height: 48px;
    background: #000000;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 2px;
    border-radius: 2px;
    margin-top: 24px;
}

.request-invite-btn span {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 48px;
    margin:0;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
}

/* dispatches */

.search__icon {
    cursor: pointer;
}

.disptches__search {
    display: flex;
    align-items: center;

}

.search__filters {
    display: flex;
}

.search__input {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search__input input {
    width: 70%;
    height: 22px;
    padding-left:32px ;
    box-sizing: border-box;
    background: transparent;
    border: none;
}

.search__input input:focus {
   outline: none;
}

.search__input input::placeholder {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
}

.search__input__clear {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #E37158;
    cursor: pointer;
}

.search__filters p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #666666;
    margin:0 32px 0 32px;
    cursor: pointer;
}

.dispatches {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
}

/*

.dispatches__preview {
    background: #FDFBF7;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    height: fit-content;
}

.dispatches__preview a {
    text-decoration: none;
}

.dispatches__preview::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 4px;
    left: 4px;
    background: #FDFBF7;
    border: 1px solid #000000;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: -1;
}

.blog__preview__img img {
    width: 100%;
}

.dispatches__preview__content {
  padding-top: 32px;
  padding-left: 40px;
  padding-bottom: 28px;
  padding-right: 36px;
}

.dispatches__preview__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #000000;
    margin:0;
}

.info__container {
   display: flex;
   align-items: center;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dispatches__preview__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top:24px
}



.view-arrow {
    align-self: flex-end;

}

.dispatch__img {
margin-right: 12px;
}

.dispatch__author {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #666666;
}

.dispatches__container {
    height: fit-content;
}
*/
.load__more__about {
    cursor: pointer;
    margin-top: 46px;
    width: 100%;
    height: 48px;
    background: #FDFBF7;
    border: 1px solid #999999;
    border-radius: 2px;
    transition: 0.3s ease;
}

.load__more__about:hover {
    border: 1px solid #3D8F44;
}

.load__more__about:hover p  {
    color: #3D8F44;
}

.load__more__about p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 48px;
    margin: 0;

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    transition: 0.3s;
}

@media only screen and (max-width: 1380px){
    .about__content, .about__headline {
        grid-template-columns:300px 600px;
        grid-template-rows: 750px 300px;
       
    }

    .about__content .dispatches::before {
        width: 950px;
    }

    .about__wrapper {
        width: 950px;
    }
}

@media only screen and (max-width: 1050px){

    .about__wrapper {
        width: 100%;
        padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    } 

    .team__content {
        grid-area: team;
    }
    
    .dispatches__container {
        grid-area: dispatches;
    }

    .mission__content {
        grid-area: mission;
    }

    .about__content {
        grid-template-columns:600px;
        grid-template-rows: auto;
        grid-template-areas: 
        "dispatches"
        "team"
        "mission";
        
        justify-content: center;
    }

    .about__content .dispatches::before {
        width: 100%;
        left: 0;
    }

    .contact-us-btn {
        margin-right: 56px;
    }

    .load__more__about {
        margin-bottom: 68px;
    }
}

    
@media only screen and (max-width: 650px){
    .about__content {
        grid-template-columns: 100%;
    }
    .dispatches__preview__info {
        display: block;
    }

    .dispatch__tag {
        margin-left: 0;
    }

    .team-bio h2{
        margin-top: 0;
    }
}


@media only screen and (max-width: 420px){

    .dispatches__preview__title {
        font-family: Schnyder M;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0.5px;
        color: #000000;
    }

    .dispatches__preview__content {
        padding: 20px 20px 24px 20px;
    }

    .dispatches__preview__info {
        flex-direction: column;
    }

    .about__content {
        margin-top: 100px;
    }

    .search__filters p {
        margin-left: 0;
    }

    .search__icon {
        margin-right: 32px;
    }

    .search__input input {
        padding-left: 0;
    }

    


 
}



