.hero__wrapper {
  width: 1200px;
  /* height: 100vh; */
  overflow: hidden;
  margin: 0 auto;
}

.hero {
  margin-top: 120px;
  position: relative;
  z-index: 1;
}

.hero__title {
  font-family: Schnyder M;
  font-style: normal;
  font-size: 120px;
  line-height: 136px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Black */
  margin: 0;
  color: #000000;
}

.t0 {
  opacity: 0;
  animation: 0.7s rollout forwards 1s;
}

.t1 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.2s;
}

.t2 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.4s;
}

.t3 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.6s;
}

.t4 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.8s;
}

.t5 {
  opacity: 0;
  animation: 0.7s rollout forwards 1.8s;
}

.t6 {
  opacity: 0;
  animation: 0.7s rollout forwards 2s;
}

.t7 {
  opacity: 0;
  animation: 0.7s rollout forwards 2.2s;
}

@keyframes rollout {
  0% {
    transform: translateY(100px) rotate(2deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

.hero__title span {
  color: #3d8f44;
}

.hero__subtitle {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  /* Black */

  color: #000000;
}

.call-to-action__holder {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.call-to-action {
  display: flex;
  justify-content: space-between;
}

.adjust__btn {
  transform: translateY(40px);
}

.scroll-down-btn {
  position: relative;
  cursor: pointer;
}
.scroll-down-btn:hover .scroll-down-btn__arrow {
  transform: translate(-50%, -35%);
}

.scroll-down-btn__arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease;
}

.scroll-down-btn__circle {
  animation: rotate 13s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1550px) {
  .hero__wrapper {
    width: 1320px;
  }

  .wrapper__arched {
    padding-top: 182px;
  }

  .hero__title {
    font-size: 120px;
    line-height: 128px;
    letter-spacing: 0.02em;
  }

  .hero__subtitle {
    font-size: 17px;
    line-height: 23px;
  }

  .scroll-down-btn {
    width: 162px;
    height: 162px;
  }

  .scroll-down-btn__circle {
    width: 100%;
  }

  .scroll-down-btn__arrow {
    width: 33px;
  }
}

@media only screen and (max-width: 1300px) {
  .hero__wrapper {
    width: 1000px;
  }
}

@media only screen and (max-width: 1250px) {
  .hero__text {
    height: 580px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 1100px) {
  .hero__wrapper {
    width: 900px;
  }
  .hero {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 950px) {
  .hero__wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .hero__text {
    width: 90%;
  }
  .hero__title {
    font-size: 100px;
    line-height: 116px;
  }

  .call-to-action {
    justify-content: unset;
  }

  .scroll-down-btn {
    margin-left: 250px;
  }

  .scroll-down-btn {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 850px) {
  .scroll-down-btn {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 770px) {
  .hero__text {
    width: 100%;
    height: 380px;
  }

  /*

    .hero__title {
        font-size: 80px;
        line-height: 90px;
    }

    */

  .hero__title {
    font-size: 90px;
    line-height: 90px;
  }

  .call-to-action {
    flex-direction: column;
    margin-top: 40px;
  }

  .scroll-down-btn {
    width: 139px;
    height: 139px;
    align-self: flex-end;
    margin-left: 0;
    margin-top: 40px;
  }

  .scroll-down-btn {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .hero__title {
    font-size: 80px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 640px) {
  .hero__wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 580px) {
  .hero__title {
    font-size: 70px;
    line-height: 78px;
  }

  .scroll-down-btn {
    width: 112px;
    height: 112px;
    align-self: flex-end;
    margin-top: 36px;
    margin-right: 40px;
  }

  .scroll-down-btn__circle {
    width: 100%;
    height: 100%;
  }

  .scroll-down-btn__arrow {
    width: 22px;
  }
}

@media only screen and (max-width: 520px) {
  .hero {
    margin-top: 100px;
  }

  .scroll-down-btn {
    display: none;
  }

  .animation__btn {
    width: 100%;
  }

  .call-to-action__holder {
    flex-direction: column;
    align-items: flex-start;
  }

  .call-to-action {
    flex-direction: column;
    margin-top: 78px;
  }

  .login__btn {
    margin-left: 0;
    margin-top: 16px;
  }

  .hero .RequestBtn,
  .hero .request__btn {
    width: 100%;
  }

  .hero .request__btn::after {
    width: 100%;
  }

  .hero .login__btn,
  .hero .LoginBtn {
    width: 100%;
  }

  .hero .login__btn::after {
    width: 100%;
  }
}

@media only screen and (max-width: 510px) {
  .hero__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    width: 336px;
  }
}

@media only screen and (max-width: 430px) {
  .hero__subtitle {
    font-size: 14px;
  }

  .hero__title {
    font-family: Schnyder M;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 58px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    width: 336px;
  }

  .hero__text {
    height: 250px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 374px) {
  .hero__title {
    font-size: 46px;
  }
}
