.RequestBtn {
    width: 200px;
    height: 60px;
    border-radius: 2px;
    background-color: #3D8F44;
    border: 1px solid #3D8F44;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s ease;
}

.RequestBtn:focus {
    outline: none;
}

.request__btn {
    width: 200px;
    height: 60px;
    position: relative;
}

.RequestBtn:hover {
    transform: translate(4px, 4px);

}

.request__btn::after {
    content:"";
    width: 200px;
    height: 60px;
    border: 1px solid #3D8F44;
    background-color: #EDECE8;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;
}

.RequestBtn span {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 60px;
    margin: 0;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media only screen and (min-width: 1550px){
    .RequestBtn {
        width: 238px;
        height: 74px;
    }

    .request__btn {
        width: 238px;
        height: 74px;
    
    }
    
    .RequestBtn:hover {
        transform: translate(5px, 5px);
    }
    
    
    .request__btn::after {
        content:"";
        width: 238px;
        height: 74px;
        top: 5px;
        left: 5px;
    }
    
    .RequestBtn span {
        font-size: 14px;
        line-height: 74px;
    }
}